import React from 'react'

import { useMutation } from '@tanstack/react-query'

import { FrankieButton } from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { entityApi } from '../../api/entity.api'
import { ENTITY_KEY } from '../../entity.key'
import { entityEn } from '../../locale/entity.en'
import { EntityID } from '../../model/entity.model'
import {
  useEntityDataQuery,
  useUpdateProfileData,
} from '../../state/entity-data/entity-data.query'

type Props = {
  assignee: string
  entityIds?: string[]
}

const mutationKey = 'assignEntity'

export const useAssignEntityMutation = (entityId?: EntityID) => {
  const { data } = useEntityDataQuery(entityId)
  const { updateProfileData } = useUpdateProfileData(entityId)
  const t = useI18n([ENTITY_KEY], { keys: entityEn })
  const { mutate, isLoading } = useMutation({
    mutationKey: [mutationKey, entityId],
    mutationFn: async (props: Props) => {
      if (!entityId) {
        throw new Error('Entity ID is required')
      }
      const { data } = await entityApi.assignEntity(entityId, props)
      return data
    },
    onSuccess: ({ meta, data: serviceProfiles }, { assignee, entityIds }) => {
      const serviceProfileMapped = serviceProfiles.map(i => i.serviceProfile)
      const serviceProfileIds = serviceProfileMapped.map(i => i.entityId)
      updateProfileData(serviceProfileMapped)
      if (meta.failed) {
        notification.error(
          ({ closeToast }) => (
            <div className="flex items-center justify-between min-w-[290px]">
              {meta.failed === meta.total
                ? t('assignEntity.failed')
                : t('assignEntity.partial', { count: meta.failed })}
              <FrankieButton
                onClick={() => {
                  mutate({
                    assignee,
                    entityIds: entityIds?.filter(
                      i => !serviceProfileIds.includes(i),
                    ),
                  })
                  if (closeToast) closeToast()
                }}
                size="xs"
                className="whitespace-nowrap"
                startIcon={{
                  name: 'mdiReload',
                  size: 'xs',
                  className: ' mr-2',
                }}
              >
                {t('assignEntity.tryAgain')}
              </FrankieButton>
            </div>
          ),
          {
            autoClose: false,
            className:
              ' mt-2 flex flex-initial w-96 min-h-10 bg-tertiary-grey-800 relative p-3.5 pr-3 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer',
          },
        )
      }
      if (data?.individual?.entityType)
        trackingManager.track(
          data.individual.entityType === 'INDIVIDUAL'
            ? TrackingEventsTypes.UserAssignIndividual
            : TrackingEventsTypes.UserAssignOrganization,
        )
    },
  })
  return { data, mutate, isLoading }
}
