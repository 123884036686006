import { ICountryOption } from 'entities/country'
import { DocumentIdTypes } from 'entities/document'
import { IOrganisation } from 'entities/organisation'
import { IRecipeOption } from 'entities/recipe'
import { PermissionTypes } from 'entities/role'
import { IUser } from 'entities/user'

import { isAllFeatureFlags } from 'shared/environment'
import { DefaultAllType, Nullable, PathArray } from 'shared/typescript'

/**
 * [IPortalFeatureConfig]
 * {@link https://github.com/frankieone/frontend-shared-library/blob/main/src/model/v2/PortalFeatureConfig.ts}
 */
export type Feature = {
  embeddableDashboard?: {
    thoughtSpot: {
      enabled?: boolean
      autoCreate?: boolean
      dashboardId?: string
      username?: string
      hostUrl?: string
      validity?: number
      organisationId?: string
    }
  }
  bulkAssignEnabled?: boolean
  bulkAssignLimit?: number
  frankie2customer?: boolean
  frankie2customerR2?: boolean
  customViews?: boolean
  KYCIndividualReports?: boolean
  abrLookup?: {
    endpoint: null
  }
  applicantSubmitSplitConsent?: boolean
  applicantSubmissionForceCheck?: boolean
  connectors?: {
    con_sardine: boolean
  }
  fraudAlerts?: boolean
  optionalFields?: {
    givenName: boolean
    familyName: boolean
    dateOfBirth: boolean
    phoneNumber: boolean
    email: boolean
    consents: boolean
    address: boolean
    document: boolean
  }
  customerSettings?: {
    defaultCountry: Nullable<string>
  }
  blockList?: {
    firstNameOptional: boolean
    lastNameOptional: boolean
    dobOptional: boolean
  }
  biometrics?: {
    SMSContent: Nullable<string>
    SMSHeaderText: Nullable<string>
    SMSExpiryInHours: Nullable<number>
    refreshResults: Nullable<boolean>
    releaseVersion: Nullable<number>
    hideSmsDialog?: Nullable<boolean>
  }
  sendKycLink?: {
    kycSmartUIURL: null
    kycSMSHeaderText: null
    kycSMSContent: Nullable<string>
    kycSMSLinkExpiry: null
    kycSMSLinkConfig: null
    hideSmsDialog?: Nullable<boolean>
  }
  kybReport?: {
    checkType: null
    entityCategoriesSingleLevel: null
    entityCategoriesUbo: null
  }
  alwaysAllowManualPass?: boolean
  facialDuplicatesEnabled?: boolean
  qldDlCardNumberRequired?: boolean
  vicDlCardNumberRequired?: boolean
  additionalFields?: {
    buddhistDOB: Nullable<boolean>
    nativeName: Nullable<boolean>
  }
  amlOngoingMonitoring?: boolean
  showAMLMatchStrength?: boolean
  transactionMonitoring?:
    | {
        aml?: boolean
        fraud?: boolean
        device?: boolean
      }
    | boolean
  twilioSubAccountSid?: string
  internationalKYB?: boolean
  internationalKybAML?: boolean
  trustAnalyser?: boolean
  verticalNavBar: boolean
  eventNotifications?: boolean
  batchNotifications?: boolean
  slackNotifications?: boolean
  vue2ReactMigration?: Partial<Record<Vue2ReactMigrationFlagTypes, boolean>>
}

export enum Vue2ReactMigrationFlagTypes {
  ReactDashboardPage = 'reactDashboardPage',
  ReactBlocklistPage = 'reactBlocklistPage',
  ReactIndividualProfilePage = 'reactIndividualProfilePage',
  ReactApplicantProfileGeneralInformation = 'reactApplicantProfileGeneralInformation',
  ReactApplicantProfileSupportingDocuments = 'reactApplicantProfileSupportingDocuments',

  ReactApplicantProfilePersonalInfo = 'reactApplicantProfilePersonalInfo',
  ReactApplicantProfileBlocklistMatches = 'reactApplicantProfileBlocklistMatches',
  ReactApplicantProfileBlocklistedInfo = 'reactApplicantProfileBlocklistedInfo',
  ReactApplicantProfilePepSanctions = 'reactApplicantProfilePepSanctions',
  ReactApplicantProfileBiometricsOcr = 'reactApplicantProfileBiometricsOcr',
  ReactApplicantProfileRiskIndicators = 'reactApplicantProfileRiskIndicators',
  ReactApplicantProfileFraudCheck = 'reactApplicantProfileFraudCheck',
  ReactApplicantProfilePotentialMatches = 'reactApplicantProfilePotentialMatches',
  ReactApplicantProfileDuplicates = 'reactApplicantProfileDuplicates',
  ReactApplicantProfileTransactions = 'reactApplicantProfileTransactions',
  ReactApplicantProfileCharacteristics = 'reactApplicantProfileCharacteristics',
  ReactApplicantProfileAuditReport = 'reactApplicantProfileAuditReport',
  ReactApplicantProfileOwnersOfficeholders = 'reactApplicantProfileOwnersOfficeholders',
  ReactApplicantManualIdv = 'reactApplicantManualIdv',
  ReactApplicantEditBusiness = 'reactApplicantEditBusiness',
}

type DocumentOptions = {
  points: number
  types?: DocumentIdTypes[]
}

// Define types for country-specific document options
export type CountryDocumentOptions = {
  primary_photographic: DocumentOptions
  primary_nonphotographic: DocumentOptions
  secondary?: DocumentOptions
}

// Define type for document verification options
type DocumentVerificationOptions = {
  passing_score: number
  max_documents: number
  passing_combinations: { [index: number]: DocumentOptions[] }
  countries: Record<string, CountryDocumentOptions>
}

export type PageData = {
  token: string
  user: IUser
  mfaBlockTS?: number // block mfa resend timestamp
  organisation: IOrganisation
  organization: IOrganisation
  features: Feature
  countries?: ICountryOption[]
  // not typed yet
  messages?: object
  mkyc_rules?: DocumentVerificationOptions
  permissions?: PermissionTypes[]
  profiles?: {
    profile_options: IRecipeOption[]
    profile_options_for_business: IRecipeOption[]
    default_profile?: unknown
    default: unknown
  }
  security?: object
  suprSend?: {
    suprSendSubscriberId: string
    suprSendWorkspaceKey: string
    suprSendPortalBaseURL: string
  }
}

export function getIsFeatureFlagEnabled(
  session: PageData | undefined,
  flags: Array<keyof PageData['features'] | PathArray<PageData['features']>>,
): boolean {
  if (isAllFeatureFlags) {
    return true
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!session || !session.features) {
    return false
  }
  for (const flagOrPathToFlag of flags) {
    if (Array.isArray(flagOrPathToFlag)) {
      let nestedValue: DefaultAllType = session.features as DefaultAllType
      flagOrPathToFlag.forEach(item => {
        if (nestedValue && typeof nestedValue === 'object') {
          const keyC = item as keyof typeof nestedValue
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          nestedValue = nestedValue[keyC]
        } else {
          nestedValue = false
        }
      })

      if (!nestedValue) {
        return false
      }
    } else if (!session.features[flagOrPathToFlag]) {
      return false
    }
  }
  return true
}

export interface IPathUnderFeatureFlag {
  featureFlags?: Array<
    keyof PageData['features'] | PathArray<PageData['features']>
  >
  reactMigrationFlag?: Vue2ReactMigrationFlagTypes
  protectedValue: string
  fallbackValue: string
}
