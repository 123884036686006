import React, { useMemo, useState, useEffect, useCallback } from 'react'

import {
  DataGridPro,
  type GridColDef,
  type GridSortModel,
  type GridRowParams,
} from '@mui/x-data-grid-pro'
import { useNavigate } from 'react-router-dom'

import {
  ApplicantRelatedOrganisationsNameCell,
  ApplicantRelatedOrganisationsRelationCell,
} from './applicant-related-organisations-cells'
import { ApplicantRelatedOrganisationsOverlay } from './applicant-related-organisations-overlays'
import {
  useFetchApplicantRelatedOrganisations,
  useGetRowApplicantRelatedOrganitions,
} from '../../state/applicant-related-organisations-state'

const MIN_TABLE_WIDTH = 650
const getCellWidth = (width: number) => ({
  minWidth: width,
  flex: width / MIN_TABLE_WIDTH,
})

interface IProps {
  applicantId: string
  emptyText: {
    empty: string
    error: string
  }
  headers: {
    name: string
    type: string
    relationships: string
  }
}

export function ApplicantRelatedOrganisationsTable(props: IProps) {
  const navigate = useNavigate()

  const cols = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: props.headers.name.toUpperCase(),
        sortable: false,
        renderCell: ApplicantRelatedOrganisationsNameCell,
        ...getCellWidth(500),
      },
      {
        field: 'type',
        headerName: props.headers.type.toUpperCase(),
        sortable: false,
        ...getCellWidth(300),
      },
      {
        field: 'relationships',
        headerName: props.headers.relationships.toUpperCase(),
        sortable: false,
        ...getCellWidth(316),
        renderCell: ApplicantRelatedOrganisationsRelationCell,
      },
    ],
    [props.headers.name, props.headers.relationships, props.headers.type],
  )

  const { data, loading, error } = useFetchApplicantRelatedOrganisations(
    props.applicantId,
  )

  const rows = useGetRowApplicantRelatedOrganitions(data)
  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [columns, setColumns] = useState(cols)

  useEffect(() => {
    if (rows.length > 0) {
      const updatedColumns = cols.map(col =>
        col.field === 'name' ? { ...col, sortable: true } : col,
      )

      setColumns(updatedColumns)
    }
  }, [cols, rows.length])

  const handleClick = useCallback(
    (params: GridRowParams) => {
      const entityId = (params.id as string).split('@')[0]
      navigate(`/new/entities/profile/${entityId}/general-information`)
    },
    [navigate],
  )

  const locale = useMemo(() => {
    let label = ''

    if (!loading && !error && rows.length === 0) {
      label = props.emptyText.empty
    } else if (!loading && error) {
      label = props.emptyText.error
    }

    return { noRowsLabel: label }
  }, [
    error,
    loading,
    rows.length,
    props.emptyText.empty,
    props.emptyText.error,
  ])

  return (
    <DataGridPro
      rows={rows}
      columns={columns.map(col => ({
        cellClassName: '!pl-4',
        headerClassName:
          '!pl-4 !pr-0 text-xs font-medium text-tertiary-grey-500 !outline-none bg-tertiary-grey-50',
        ...col,
      }))}
      className="[&_.MuiDataGrid-virtualScrollerContent]:border-neutral-30 mt-6 !border-t-0 !border-x-0"
      getCellClassName={() =>
        '!outline-none !border-neutral-30 !items-start !py-4 !px-0 break-words !min-h-full !max-h-full'
      }
      localeText={locale}
      getRowClassName={() => 'cursor-pointer'}
      loading={loading}
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnFilter
      disableColumnMenu
      disableDensitySelector
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
      hideFooterPagination
      hideFooter
      hideFooterSelectedRowCount
      sortModel={sortModel}
      getRowHeight={props => {
        // if there are more than 1 relationship, increase the height of the row
        const { relationships = [] } = props.model as {
          relationships: string[]
        }

        return relationships.length > 1 ? relationships.length * 30 : null
      }}
      slots={{
        noRowsOverlay: ApplicantRelatedOrganisationsOverlay.bind(null, {
          error,
          text: locale.noRowsLabel,
        }),
      }}
      sx={{
        '& .MuiDataGrid-virtualScroller':
          rows.length > 0 ? {} : { minHeight: '60px' },
        '.MuiDataGrid-overlayWrapperInner': { padding: '16px' },
        '.MuiDataGrid-columnSeparator': { display: 'none' },
        '.MuiDataGrid-cell': { flexFlow: 'column', justifyContent: 'center' },
        '.MuiDataGrid-overlay': !loading
          ? {
              justifyContent: 'flex-start',
              color: error ? '#E02424' : '#374151',
            }
          : {},
      }}
      onSortModelChange={setSortModel}
      onRowClick={handleClick}
    />
  )
}
