import React, { useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import { UseFormReturn } from 'react-hook-form'

import {
  FrankieButton,
  FrankieCheckbox,
  FrankieLoader,
  FrankiePopover,
} from 'frankify/src'

import { defaultAddressFields } from 'features/individual-profile-f2/model/address.model'

import { AddressCategoryTypes } from 'entities/applicant'
import { IStateOption, countryList, stateList } from 'entities/country'

import { SelectFormField, TextFormField } from 'shared/form'
import { I18nKeys, useI18n } from 'shared/i18n'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../../individual-profile-f2.key'
import { individualProfileF2En } from '../../../locale/individual-profile-f2.en'
import {
  Address,
  IIndividualProfileF2FormType,
} from '../../../model/individual-profile-f2-form.model'
import {
  singleAddressDataQa,
  singleAddressFormF2Qa,
} from '../../../qa/individual-profile-f2.qa'
import { useGetDetailAddress } from '../../../state/getDetailAddress.data'
import { useGetAddressSuggestion } from '../../../state/getSearchAddress.data'

type Props = {
  form: UseFormReturn<IIndividualProfileF2FormType>
  idx: number
  isOptional?: boolean
}

function splitStringWithZero(inputString: string, subString: string) {
  subString = subString.trim()
  // Find the index where the substring occurs
  const index = inputString
    .toLowerCase()
    .indexOf(subString.trim().toLowerCase())

  // If the substring is not found, return original string as an array
  if (index === -1) {
    return [inputString]
  }

  // Check if the substring has a space before and after
  const haveRight = inputString.at(index + subString.length) === ' '
  const haveLeft = inputString.at(index) === ' '

  // Split the string where the substring occurs
  const parts = [
    inputString.substring(0, index),
    <b className={classNames(haveRight && 'mr-1', haveLeft && 'ml-1')}>
      {inputString.substring(index, index + subString.length)}
    </b>,
    inputString.substring(index + subString.length),
  ]

  return parts
}

export function SingleAddressForm({ form, idx, isOptional }: Props) {
  const [showManualAddress, setShowManualAddress] = useState<boolean | null>(
    null,
  )

  const [openAddressSuggestion, setOpenAddressSuggestion] = useState(false)

  const [selectedGoogleAddressId, setSelectedGoogleAddressId] = useState('')

  const { control, resetField, watch, setValue, getValues, setFocus, trigger } =
    form

  const sameAsCurrent = watch('sameAsCurrent')

  const t = useI18n(INDIVIDUAL_PROFILE_F2_KEY, { keys: individualProfileF2En })

  const countryOptions = useMemo(
    () =>
      countryList.map(country => ({
        label: country.name,
        value: country.alpha3code,
      })),
    [],
  )

  const getFieldName = (name: keyof Address) =>
    `addresses.${idx}.${name}` as keyof IIndividualProfileF2FormType

  const fieldMap: {
    name: keyof Address
    className?: string
    optional?: boolean
    isSelect?: true
    options?: IStateOption[]
    placeholder?: I18nKeys<typeof individualProfileF2En>
    error?: I18nKeys<typeof individualProfileF2En>
  }[] = [
    {
      name: 'buildingName',
      className: 'col-span-4 !w-[48%]',
      optional: true,
    },
    {
      name: 'unitNumber',
      optional: true,
      placeholder: 'addressForm.unitNumberPlaceholder',
    },
    {
      name: 'streetNumber',
      className: 'col-span-1',
      error: 'addressForm.streetNumberError',
      placeholder: 'addressForm.streetNumberPlaceholder',
    },
    {
      name: 'streetName',
      className: 'col-span-2',
      error: 'addressForm.streetNameError',
      placeholder: 'addressForm.streetNamePlaceholder',
    },
    {
      name: 'locality',
      className: 'col-span-2',
      error: 'addressForm.suburbError',
    },
    {
      name: 'subdivision',
      isSelect: true,
      options: stateList.AUS,
      error: 'addressForm.stateError',
    },
    {
      name: 'postalCode',
    },
  ]

  const { data: detailAddress = undefined } = useGetDetailAddress(
    selectedGoogleAddressId,
  )

  const searchTerm = watch(getFieldName('unstructuredLongForm')) as string
  const country = watch(getFieldName('country')) as string
  const addressType = (watch(getFieldName('type')) ||
    AddressCategoryTypes.current) as AddressCategoryTypes

  const { data: placesData = [], isFetching } = useGetAddressSuggestion(
    searchTerm,
    [country] as string[],
  )

  useEffect(() => {
    if (detailAddress) {
      type DetailAddressKeyType = keyof typeof detailAddress

      delete (detailAddress as unknown as { data?: object }).data

      for (const detailAddressKey in detailAddress) {
        if (Object.hasOwn(detailAddress, detailAddressKey)) {
          const addressValue =
            detailAddress[detailAddressKey as DetailAddressKeyType]

          const isCurrentlyEmpty = !getValues(
            getFieldName(detailAddressKey as DetailAddressKeyType),
          )

          if (addressValue || !isCurrentlyEmpty) {
            setValue(
              getFieldName(detailAddressKey as DetailAddressKeyType),
              addressValue,
            )
          }
        }
      }
      setOpenAddressSuggestion(false)
      setSelectedGoogleAddressId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailAddress])

  const getRequiredRule = ({
    label,
    isSelect,
    error,
  }: {
    label: I18nKeys<typeof individualProfileF2En>
    isSelect?: boolean
    error?: I18nKeys<typeof individualProfileF2En>
  }) =>
    isOptional
      ? {}
      : {
          required: error
            ? t(error)
            : t(isSelect ? 'errors.select' : 'errors.requiredEntity', {
                label: t(label).toLowerCase(),
              }),
        }

  const handleSelectedAddress = (googleAddressId: string) => {
    setOpenAddressSuggestion(false)

    setSelectedGoogleAddressId(googleAddressId)

    setValue(
      getFieldName('unstructuredLongForm'),
      placesData.find(i => i.value === googleAddressId)?.label,
    )

    const [ref] = document.getElementsByName(
      getFieldName('unstructuredLongForm'),
    )

    setTimeout(() => {
      ref.blur()
    }, 0)
  }

  useEffect(() => {
    //
    if (getValues(getFieldName('addressId'))) {
      if (!getValues(getFieldName('unstructuredLongForm'))) {
        for (const key in defaultAddressFields) {
          if (getValues(getFieldName(key as keyof Address))) {
            setShowManualAddress(true)
            return
          }
        }
      }
    } else if (
      getValues(getFieldName('type')) !== AddressCategoryTypes.current
    ) {
      setFocus(getFieldName('unstructuredLongForm'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showSameAs = addressType === AddressCategoryTypes.mailing

  const handleUiChange = (showManualInput: boolean) => {
    setShowManualAddress(showManualInput)
    setValue('sameAsCurrent', false)
    setValue(`addresses.${idx}`, {
      ...getValues(`addresses.${idx}`),
      ...defaultAddressFields,
    })
    if (showManualInput) {
      setFocus(getFieldName('buildingName'))
    } else {
      setFocus(getFieldName('unstructuredLongForm'), { shouldSelect: true })
    }
  }

  const sameAsCurrentCheckBox = useMemo(
    () => (
      <div className="flex col-span-4 gap-2">
        <FrankieCheckbox
          onChange={() => {
            void trigger('addresses.0', { shouldFocus: true }).then(() => {
              resetField(`addresses.${idx}`, {
                defaultValue: { country, type: addressType },
              })
              setValue('sameAsCurrent', !sameAsCurrent)
            })
          }}
          checked={!!sameAsCurrent}
        />
        <span>{t('addressForm.sameAsCurrent')}</span>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sameAsCurrent],
  )

  if (sameAsCurrent && showSameAs) {
    return (
      <div
        className="grid grid-cols-4 gap-x-6 gap-y-4"
        data-qa={singleAddressFormF2Qa.wrapper}
      >
        {sameAsCurrentCheckBox}
      </div>
    )
  }

  return (
    <div
      className="grid grid-cols-4 gap-x-6 gap-y-4"
      data-qa={singleAddressFormF2Qa.wrapper}
    >
      {showSameAs && sameAsCurrentCheckBox}
      <div className="col-span-2">
        <SelectFormField
          label={t('addressForm.country')}
          options={countryOptions}
          control={control}
          disabled
          name={getFieldName('country')}
          className="[&_.frankie-select-toggle]:!hidden"
          testId={{ input: singleAddressFormF2Qa.country }}
        />
        {showManualAddress && (
          <div className="font-sm text-tertiary-grey-500 mt-2">
            {t('cannotBePOBox')}
          </div>
        )}
      </div>
      <div className="col-span-2" />

      {!showManualAddress ? (
        <div className="col-span-4 !w-[50vw] max-w-[645px] -mt-4 relative bg-white-100 w-full">
          <FrankiePopover
            open={openAddressSuggestion}
            onOpenChange={open => {
              if (!selectedGoogleAddressId)
                setValue(getFieldName('unstructuredLongForm'), '')
              setOpenAddressSuggestion(open)
            }}
            popoverRest={{ placement: 'bottom-start' }}
            initialFocus={-1}
            trigger={
              <div>
                <TextFormField
                  trim
                  data-hj-suppress
                  testId={{ input: singleAddressFormF2Qa.unstructuredLongForm }}
                  control={control}
                  name={getFieldName('unstructuredLongForm')}
                  type="search"
                  closeButton={{
                    onClick: () => {
                      setSelectedGoogleAddressId('')
                      setValue(`addresses.${idx}`, {
                        ...getValues(`addresses.${idx}`),
                        ...defaultAddressFields,
                      })
                      setOpenAddressSuggestion(false)
                    },
                  }}
                  autoComplete="off"
                  className="mt-4 mb-4"
                  onClick={() => {
                    setOpenAddressSuggestion(true)
                  }}
                  label=""
                  rules={{
                    required:
                      !isOptional &&
                      t('errors.requiredEntity', {
                        label: t(`addressForm.${addressType}`),
                      }),
                  }}
                  isSearchIcon
                  showErrorText
                  searchIconPlacement="end"
                  placeholder={t('profileForm.searchAddress')}
                />
              </div>
            }
          >
            <div className="w-full justify-start !w-[50vw] max-w-[645px]  bg-surface  shadow-md  rounded-sm">
              <div>
                <ul>
                  {placesData.length ? (
                    placesData.map(item => (
                      <li
                        role="option"
                        aria-selected
                        tabIndex={-1}
                        id={item.label}
                        data-hj-suppress
                      >
                        <FrankieButton
                          className="py-2 px-2 h-[48px] w-full  flex items-center cursor-pointer hover:bg-primary-50"
                          noStyles
                          key={item.value}
                          onClick={() => handleSelectedAddress(item.value)}
                          testId={{ button: singleAddressFormF2Qa.placesData }}
                        >
                          {splitStringWithZero(item.label, searchTerm)}
                        </FrankieButton>
                      </li>
                    ))
                  ) : (
                    <li
                      role="option"
                      aria-selected
                      tabIndex={-1}
                      className="py-2 px-2 h-[48px] flex items-center"
                      data-qa={singleAddressFormF2Qa.noSearchResults}
                    >
                      {t('noSearchResults')}
                      {isFetching && (
                        <FrankieLoader
                          loading
                          size="xs"
                          className="ml-auto mr-5 w-min"
                        />
                      )}
                    </li>
                  )}
                  <li
                    tabIndex={-1}
                    className="py-1 px-2 h-[48px] flex items-center"
                  >
                    <FrankieButton
                      role="option"
                      className="text-primary-900 "
                      noStyles
                      onClick={() => handleUiChange(true)}
                      testId={{
                        button: singleAddressFormF2Qa.enterAddressManually,
                      }}
                    >
                      {t('addressForm.enterAddressManually')}
                    </FrankieButton>
                  </li>
                </ul>
              </div>
            </div>
          </FrankiePopover>
          <div className="font-sm text-tertiary-grey-500 -mt-3 ">
            {t('cannotBePOBox')}
          </div>
        </div>
      ) : (
        <>
          {fieldMap.map(field =>
            field.isSelect ? (
              <SelectFormField
                key={field.name}
                options={field.options || []}
                label={t(`addressForm.${field.name}`)}
                name={getFieldName(field.name)}
                className={field.className}
                control={control}
                autocomplete
                rules={
                  field.optional
                    ? {}
                    : getRequiredRule({
                        error: field.error,
                        label: `addressForm.${field.name}`,
                        isSelect: true,
                      })
                }
                showErrorText
                testId={{ input: singleAddressDataQa(field.name) }}
              />
            ) : (
              <TextFormField
                trim
                key={field.name}
                label={t(`addressForm.${field.name}`)}
                placeholder={field.placeholder && t(field.placeholder)}
                name={getFieldName(field.name)}
                className={field.className}
                control={control}
                rules={
                  field.optional
                    ? {}
                    : getRequiredRule({
                        error: field.error,
                        label: `addressForm.${field.name}`,
                      })
                }
                showErrorText
                testId={{ input: singleAddressDataQa(field.name) }}
              />
            ),
          )}
          <div className="col-span-4">
            <FrankieButton
              noStyles
              className="text-primary-900"
              onClick={() => handleUiChange(false)}
              testId={{ button: singleAddressFormF2Qa.useAddressSearch }}
            >
              {t('addressForm.useAddressSearch')}
            </FrankieButton>
          </div>
        </>
      )}
    </div>
  )
}
