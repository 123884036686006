import React from 'react'

import { ApplicantId } from 'entities/applicant'

import { useI18n } from 'shared/i18n'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import { reportStatus } from '../../model/applicant-biometrics-ocr.model'
import { useApplicantBiometryState } from '../../state/applicant-biometrics-ocr-data-state/applicant-biometrics-ocr-data.state'
import { BiometricAccordionHeader } from '../applicant-biometrics-accordion/applicant-biometrics-accordion'
import {
  BiometricDocumentView,
  ConfidenceScore,
  TickTree,
} from '../applicant-biometrics-ocr-helper/applicant-biometrics-ocr-helper'

type Props = {
  applicantId: ApplicantId
  index: number
}

export function ApplicantBiometryReportPanel({ applicantId, index }: Props) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const {
    data: { biometryBase64, biometryReportData },
    loadingScan,
  } = useApplicantBiometryState({ applicantId, index })

  if (!biometryReportData) return null

  return (
    <BiometricAccordionHeader
      checkResult={biometryReportData.result}
      reportStatus={t(reportStatus(biometryReportData.checkStatus))}
      checkDate={biometryReportData.checkDate}
      detailType="biometry"
      applicantId={applicantId}
      index={index}
      className="min-w-[934px]"
    >
      <div className="flex gap-4 py-2">
        <BiometricDocumentView
          fileName={t('facialRecognition')}
          imageScan={biometryBase64.image}
          videoScan={biometryBase64.video}
          loading={loadingScan}
          className="max-w-[200px]"
        />
        <div className="flex gap-x-4">
          {biometryReportData.items.map(item => (
            <TickTree {...item} className="basis-1/5" />
          ))}

          <ConfidenceScore
            className="basis-1/5"
            confidenceLevel={biometryReportData.confidence_level}
          />
        </div>
      </div>
    </BiometricAccordionHeader>
  )
}
