export const mfaEn = {
  form: {
    heading: 'Multi-factor authentication',
    subheadingEmail:
      'Enter the 6-digit authentication code sent to your email inbox. The code will be valid for a short time.',
    subheadingApp: 'Enter the 6-digit code from your chosen authenticator app.',
    subheadingAppRecovery:
      'If your authentication app is not working, login with one of your 6-digit recovery codes.',
    cta: {
      confirm: 'Confirm',
      resendCode: 'Resend code',
      useAppRecovery: 'Authentication app not working?',
      dontUseAppRecovery: 'Use authentication app',
    },
    notification: {
      resendCodeSuccess: 'New authentication code has been sent to your inbox',
      resendCodeError: 'Failed to send a new authentication code',
    },
    codeLabel: 'Authentication Code',
    recoveryCodeLabel: 'Recovery Code',
    validation: {
      required: 'Authentication code required.',
    },
    errors: {
      unspecific: 'Something went wrong',
      '403': 'Check the authentication code and please try again.',
      general_app: {
        text:
          'The authentication code provided is either incorrect or has expired. ' +
          'You have limited attempts remaining before your account is locked for security.',
      },
      general_email: {
        text:
          'The authentication code provided is either incorrect or has expired. ' +
          'You have limited attempts remaining before your account is locked for security.',
        text2:
          'Please check your spam/junk folder if you have trouble finding the email.',
      },
      '403_one':
        'The authentication code could not be verified. ' +
        'You have <strong>one</strong> more attempt before your account is locked, ' +
        'or try to resend and enter the new code.',
      '403_two':
        'The authentication code could not be verified. ' +
        'You have two more attempts before your account is locked, ' +
        'or try to resend and enter the new code.',
      '403_one_app':
        'The authentication code could not be verified. ' +
        'You have <strong>one</strong> more attempt before your account is locked.',
      '403_two_app':
        'The authentication code could not be verified. ' +
        'You have two more attempts before your account is locked.',
      '429_locked':
        'Your account has been locked for security. Please contact your user administrator to reset your account.',
    },
    resendCode: {
      success:
        'We’ve sent you a new authentication code. Please note there are limits on how often you can request a new one.',
      successEmail:
        'Please check your spam/junk folder if you have trouble finding the email.',
      error:
        'You have reached the limit of attempts to resend a new code. Please try again in a few minutes.',
    },
  },
  disableMfaConfirm: {
    heading: 'Disable multi-factor authentication',
    subheading:
      'If you disable multi-factor authentication (MFA), ' +
      'your account will no longer have an extra layer of protection. ' +
      'You can re-enable MFA at any anytime.',
    cta: {
      confirm: 'Confirm',
      cancel: 'No, cancel',
    },
    notification: 'Multi-factor authentication disabled',
  },
  mfaTypeSelect: {
    heading: 'Enable multi-factor authentication',
    subheading:
      'Enhance your account security by ' +
      'implementing multi-factor authentication (MFA) for logging into FrankieOne. ' +
      'Adding an additional login step provides increased protection for your account, ' +
      'even if your password is compromised.',
    options: {
      email: {
        name: 'Email',
        descr:
          'Set up MFA using your email and get the authentication codes from your inbox.',
      },
      app: {
        name: 'Authenticator app',
        descr:
          'Set up MFA using an authenticator app such as Google Authenticator or Duo.',
      },
    },
  },
  enableMfaEmail: {
    sendEmail: {
      heading: 'Setting up MFA with email',
      subheading:
        'Click ‘Next’ to receive an authentication code to your email {{email}}. The code will expire in five minutes.',
      cta: {
        back: 'Back',
        next: 'Next',
      },
    },
    verifyEmail: {
      heading: 'Enter the 6-digit authentication code',
      codeLabel: 'Authentication Code',
      cta: {
        resend: 'Resend code',
        back: 'Back',
        verify: 'Verify',
      },
      alerts: {
        resendBlocked:
          'You have reached the amount of attempts to resend a new code in this session. Please try again in short while.',
        resendAttemptsLeft:
          'You have {{amount}} more attempts left at resending a new code to your inbox.',
        resendAttemptsLast:
          'You have <strong>one</strong> more attempts left at resending a new code to your inbox.',
      },
    },
  },
  enableMfaApp: {
    heading: 'Setting up MFA with an authentication app',
    h2: {
      scanQr:
        '1. Scan this QR code or enter the key into your authentication app',
      enterCode: '2. Enter the 6-digit authentication code here',
      saveRecoveryCodes: 'Keep your recovery codes safe',
    },
    description: {
      recoveryCodes:
        'If your smartphone or authentication app becomes unavailable or lost, ' +
        'the recovery codes provided below will be your lifeline. ' +
        'Ensure that you print, copy, or write down the codes and keep them safe and secure. ' +
        'If you lose your device and do not have a recovery code, ' +
        'you will lose access to your account.',
    },
    codeLabel: 'Authentication Code',
    cta: {
      back: 'Back',
      verify: 'Verify',
      copyKey: 'Copy key',
    },
  },
  mfaIsEnabled: {
    heading: 'Multi-factor authentication is enabled',
    subheading:
      'Great job on keeping your account secure. You will now be asked for a login authentication code whenever you log in to the FrankieOne portal. ',
    notification: 'Multi-factor authentication enabled',
    cta: {
      done: 'Done',
    },
  },
}
