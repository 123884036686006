export enum TrackingEventsTypes {
  GlobalSearchSearch = 'global-search__search',
  GlobalSearchShow = 'global-search__show',
  GlobalSearchFeedback = 'global-search__feedback',
  GlobalSearchNoResults = 'global-search_no-results__show',
  GlobalSearchDocs = 'global-search_learn__click',
  GlobalSearchRecentSearch = 'global-search_recent__click',

  FilteringEntityStatus = 'filtering__entity-status',
  FilteringIssues = 'filtering__issues',
  FilteringGeneral = 'filtering__general',
  FilteringFeedback = 'filtering__feedback',
  FilteringUnspecific = 'filtering__unspecific',

  // notifications events

  NotificationShow = 'notifications__show',
  NotificationClick = 'notifications__click',
  NotificationMarkAllRead = 'notifications_mark-all__read',

  OrganizationManualStatusUpdate = 'organization__manual-status-update',

  // settings events

  Settings = 'settings:setting__show',
  PersonalSettings = 'settings:personal__show',
  OrganizationSettings = 'settings:account__show',
  NotificationSettings = 'settings:notifications__show',
  NotificationSettingsClick = 'settings:notifications__click',
  RoleManagementSettings = 'settings:roles__show',
  UserManagementSettings = 'settings:users__show',
  SettingsUserListSearchUser = 'settings:users__search',
  SettingsUserListCreateUser = 'settings:users__create',
  SettingsUserListCreateUserError = 'settings:users_error__create',
  UserManagementUserTableEditUser = 'settings:users__edit',
  UserManagementUserTableEditUserError = 'settings:users_error__edit',
  UserManagementUserTableResetUser = 'settings:users__reset',
  UserManagementUserTableResetUserError = 'settings:users_error__reset',
  UserManagementUserTableLockUser = 'settings:users__lock',
  UserManagementUserTableLockUserError = 'settings:users_error__lock',
  UserManagementDeleteUserModalDeleteUser = 'settings:users__delete',
  UserManagementDeleteUserModalDeleteUserError = 'settings:users_error__delete',

  // Entity table / Needs review table events

  BulkActionsAssignShow = 'bulk-actions_assign__show',
  BulkActionsAssignConfirm = 'bulk-actions_assign__confirm',

  UserAssignIndividual = 'individual:user__assign',
  UserAssignOrganization = 'organisation:user__assign',

  UserArchiveIndividual = 'individual:entity_archive__show',
  UserArchiveOrganization = 'organisation:entity_archive__show',
  UserArchiveConfirmIndividual = 'individual:entity_archive__confirm',
  UserArchiveConfirmOrganization = 'organisation:entity_archive__confirm',
  UserArchiveConfirmIndividualError = 'individual:entity_archive_error__confirm',
  UserArchiveConfirmOrganizationError = 'organisation:entity_archive_error__confirm',

  UserUnarchiveIndividual = 'individual:entity_unarchive__show',
  UserUnarchiveOrganization = 'organisation:entity_unarchive__show',
  UserUnarchiveConfirmIndividual = 'individual:entity_unarchive__confirm',
  UserUnarchiveConfirmOrganization = 'organisation:entity_unarchive__confirm',
  UserUnarchiveConfirmIndividualError = 'individual:entity_unarchive_error__confirm',
  UserUnarchiveConfirmOrganizationError = 'organisation:entity_unarchive_error__confirm',

  ProfileTableViewEntities = 'entities:entity__show',
  ProfileTableViewNeedsReview = 'needs-review:entity__show',

  ProfileTableViewNoResultsEntities = 'entities:filter_no-results__show',
  ProfileTableViewNoResultsNeedsReview = 'needs-review:filter_no-results__show',

  ProfileTableViewFeedbackEntities = 'entities:filter_no-results__feedback',
  ProfileTableViewFeedbackNeedsReview = 'needs-review:filter_no-results__feedback',

  ProfileTableViewFilterWorkflowStatusEntities = 'entities:filter_workflow-status__apply',
  ProfileTableViewFilterWorkflowStatusNeedsReview = 'needs-review:filter_workflow-status__apply',

  ProfileTableViewFilterIssuesEntities = 'entities:filter_issues__apply',
  ProfileTableViewFilterIssuesNeedsReview = 'needs-review:filter_issues__apply',

  ProfileTableViewFilterAdvancedShowEntities = 'entities:filter_advanced__show',
  ProfileTableViewFilterAdvancedShowNeedsReview = 'needs-review:filter_advanced__show',
  ProfileTableViewFilterAdvancedShowEntitiesError = 'entities:filter_advanced_error__show',

  ProfileTableViewFilterAdvancedApplyEntities = 'entities:filter_advanced__apply',
  ProfileTableViewFilterAdvancedApplyNeedsReview = 'needs-review:filter_advanced__apply',

  ProfileTableViewResultsDownloadEntities = 'entities:results__download',
  ProfileTableViewResultsDownloadNeedsReview = 'needs-review:results__download',

  ProfileTableViewResultsPage2ScrollEntities = 'entities:results_page{page}__scroll',
  ProfileTableViewResultsPage2ScrollNeedsReview = 'needs-review:results_page{page}__scroll',

  // Entity view events

  EntityViewShowIndividual = 'individual:entity_view__show',
  EntityViewShowOrganisation = 'organisation:entity_view__show',
  EntityViewShowUserAssignIndividual = 'individual:user__assign',
  EntityViewShowUserAssignOrganisation = 'organisation:user__assign',
  EntityViewShowEntityEditIndividual = 'individual:entity_edit__show',
  EntityViewShowEntityEditOrganisation = 'organisation:entity_edit__show',
  EntityViewSaveVerifyIndividual = 'individual:entity_edit__save-verify',
  EntityViewSaveVerifyOrganisation = 'organisation:entity_edit__save-verify',
  EntityViewSaveIndividual = 'individual:entity_edit__save',
  EntityViewSaveOrganisation = 'organisation:entity_edit__save',
  EntityViewArchiveIndividual = 'individual:entity_archive__show',
  EntityViewArchiveOrganisation = 'organisation:entity_archive__show',
  EntityViewDeleteIndividual = 'individual:entity_delete__show',
  EntityViewDeleteOrganisation = 'organisation:entity_delete__show',
  EntityViewArchiveConfirmIndividual = 'individual:entity_archive__confirm',
  EntityViewArchiveConfirmOrganisation = 'organisation:entity_archive__confirm',
  EntityViewDeleteConfirmIndividual = 'individual:entity_delete__confirm',
  EntityViewDeleteConfirmOrganisation = 'organisation:entity_delete__confirm',

  // Workflow events
  WorkflowAmlShowIndividual = 'individual:workflow-events_aml__show',
  WorkflowEventsShowIndividual = 'individual:workflow-events__show',
  WorkflowEventSummaryEntityVerificationClickIndividual = 'individual:workflow-event_summary-entity-verification__click',
  WorkflowEventSummaryAMLClickIndividual = 'individual:workflow-event_summary-aml__click',
  WorkflowEventSummaryIDVClickIndividual = 'individual:workflow-event_summary-document__click',
  WorkflowEventSummaryOCRClickIndividual = 'individual:workflow-event_summary-biometrics__click',
  WorkflowEventEntityVerificationShowIndividual = 'individual:workflow-event_entity-verification__show',
  WorkflowEventEntityVerificationResolveIndividual = 'individual:workflow-event-entity-verification__resolve',
  WorkflowEventAMLResolveIndividual = 'individual:workflow-event-aml__resolve',
  WorkflowEventSectionNameResolveIndividual = 'individual:workflow-event-{sectionName}__resolve',
  WorkflowEventWorkflowStatusManualPassIndividual = 'individual:workflow-event_workflow-status__manual-pass',
  WorkflowEventWorkflowStatusManualFailIndividual = 'individual:workflow-event_workflow-status__manual-fail',
  WorkflowEventWorkflowStatusManualNeedsAttentionIndividual = 'individual:workflow-event_workflow-status__manual-needs-attention',
  WorkflowEventRiskShowIndividual = 'individual:workflow-event_risk__show',
  WorkflowVerificationIndividual = 'individual:workflow-event_entity-verification__show',
  EntityResolveShowIndividual = 'individual:entity_resolve__show',
  EntityResolveSaveVerifyIndividual = 'individual:entity_resolve__save-verify',

  WorkflowEventsShowOrganisation = 'organisation:workflow-events__show',
  WorkflowEventSummaryEntityVerificationClickOrganisation = 'organisation:workflow-event_summary-entity-verification__click',
  WorkflowEventEntityVerificationShowOrganisation = 'organisation:workflow-event_entity-verification__show',
  WorkflowEventEntityVerificationResolveOrganisation = 'organisation:workflow-event-entity-verification__resolve',
  WorkflowEventAMLResolveOrganisation = 'organisation:workflow-event-aml__resolve',
  WorkflowEventSectionNameResolveOrganisation = 'organisation:workflow-event-{sectionName}__resolve',
  WorkflowEventWorkflowStatusManualPassOrganisation = 'organisation:workflow-event_workflow-status__manual-pass',
  WorkflowEventWorkflowStatusManualFailOrganisation = 'organisation:workflow-event_workflow-status__manual-fail',
  WorkflowEventWorkflowStatusManualNeedsAttentionOrganisation = 'organisation:workflow-event_workflow-status__manual-needs-attention',
  WorkflowEventRiskShowOrganisation = 'organisation:workflow-event_risk__show',
  EntityResolveShowOrganisation = 'organisation:entity_resolve__show',
  EntityResolveSaveVerifyOrganisation = 'organisation:entity_resolve__save-verify',

  // AML events

  AmlEventsFilterWorkflowApply = 'aml:filter_workflow__apply',
  AmlEventsFilterMatchStatusApply = 'aml:filter_match-status__apply',
  AmlEventsFilterIssuesApply = 'aml:filter_issues__apply',
  AmlEventsFilterAmlMatchApply = 'aml:filter_aml-match__apply',

  // IDV events

  WorkflowEventsDocumentShow = 'individual:workflow-events_document__show',
  WorkflowEventsDocumentFront = 'individual:workflow-events_document__front',
  WorkflowEventsDocumentBack = 'individual:workflow-events_document__back',
  WorkflowEventsDocumentFullReportShow = 'individual:workflow-events_document-full-report__show',
  WorkflowEventsDocumentCompareShow = 'individual:workflow-events_document-compare__show',
  WorkflowEventsDocumentCompareFront = 'individual:workflow-events_document-compare__front',
  WorkflowEventsDocumentCompareBack = 'individual:workflow-events_document-compare__back',
  WorkflowEventsDocumentCompareRotate = 'individual:workflow-events_document-compare__rotate',
  WorkflowEventsDocumentCompareZoom = 'individual:workflow-events_document-compare__zoom',
  WorkflowEventsBiometricsShow = 'individual:workflow-events_biometrics__show',
  WorkflowEventsBiometricsThumbnailClick = 'individual:workflow-events_biometrics-thumbnail__click',
  WorkflowEventsBiometricsDownload = 'individual:workflow-events_biometrics__download',
  WorkflowEventsBiometricsPlay = 'individual:workflow-events_biometrics__play',
  WorkflowEventsBiometricsCompareShow = 'individual:workflow-events_biometrics-compare__show',
  WorkflowEventsBiometricsCompareThumbnailClick = 'individual:workflow-events_biometrics-compare-thumbnail__click',
  WorkflowEventsBiometricsComparePlay = 'individual:workflow-events_biometrics-compare__play',
  WorkflowEventsBiometricsCompareDocSwitch = 'individual:workflow-events_biometrics-compare-doc__switch',
  WorkflowEventsBiometricsCompareDocFront = 'individual:workflow-events_biometrics-compare-doc__front',
  WorkflowEventsBiometricsCompareDocBack = 'individual:workflow-events_biometrics-compare-doc__back',
  WorkflowEventsBiometricsCompareDocRotate = 'individual:workflow-events_biometrics-compare-doc__rotate',
  WorkflowEventsBiometricsCompareDocZoom = 'individual:workflow-events_biometrics-compare-doc__zoom',
  ProfileDocumentShow = 'individual:profile_document__show',
  ProfileDocumentFront = 'individual:profile_document__front',
  ProfileDocumentBack = 'individual:profile_document__back',
  ProfileDocumentExpandShow = 'individual:profile_document-expand__show',
  ProfileDocumentExpandRotate = 'individual:profile_document-expand__rotate',
  ProfileDocumentExpandZoom = 'individual:profile_document-expand__zoom',
  ProfileSelfieShow = 'individual:profile_selfie__show',
  ProfileSelfieScroll = 'individual:profile_selfie__scroll',
  ProfileDocumentScroll = 'individual:profile_document__scroll',
  ProfileSelfieCompareClick = 'individual:profile_selfie-compare__click',
  ProfileSelfieCompareThumbnailClick = 'individual:profile_selfie-compare-thumbnail__click',
  ProfileSelfieCompareRotate = 'individual:profile_selfie-compare__rotate',
  ProfileSelfieCompareDocSwitch = 'individual:profile_selfie-compare-doc__switch',
  ProfileSelfieCompareDocFront = 'individual:profile_selfie-compare-doc__front',
  ProfileSelfieCompareDocBack = 'individual:profile_selfie-compare-doc__back',
  ProfileSelfieCompareDocRotate = 'individual:profile_selfie-compare-doc__rotate',
  ProfileSelfieCompareDocZoom = 'individual:profile_selfie-compare-doc__zoom',

  // Send link events

  IndividualViewSendLinkClick = 'individual:entity-view_send-link__click',
  IndividualSendLinkLinkTypeShow = 'individual:send-link_link-type__show',
  IndividualSendLinkSmsMessageShow = 'individual:send-link_sms-message__show',
  IndividualSendLinkConfirm = 'individual:send-link__confirm',
  IndividualSendLinkCopy = 'individual:send-link__copy',
  IndividualSendLinkWorkflowEventClick = 'individual:workflow-event_send-link__click',

  OrganisationViewSendLinkClick = 'organisation:entity-view_send-link__click',
  OrganisationSendLinkLinkTypeShow = 'organisation:send-link_link-type__show',
  OrganisationSendLinkSmsMessageShow = 'organisation:send-link_sms-message__show',
  OrganisationSendLinkConfirm = 'organisation:send-link__confirm',
  OrganisationSendLinkCopy = 'organisation:send-link__copy',
  OrganisationSendLinkWorkflowEventClick = 'organisation:workflow-event_send-link__click',

  // Fraud events

  FraudViewOverviewPage = 'fraud__view-overview-page',
  FraudViewPersonalInfoPage = 'fraud__view-personal-info-page',
  FraudViewResultsPhone = 'fraud__view-results-phone',
  FraudViewResultsDevice = 'fraud__view-results-device',
  FraudViewResultsEmail = 'fraud__view-results-email',
  FraudViewResultsIpLocation = 'fraud__view-results-ip-location',

  // Login Events

  LoginFormView = 'login:login__show',
  LoginFormUserLogin = 'login-login__click',
  LoginFormUserResetPassport = 'login-reset-password__click',

  // Create entity Events
  IndividualProfileCreateEntity = 'individual:entity_create__show',
  IndividualProfileCreateSaveAndVerifyEntity = 'individual:entity_create__save-verify',
  IndividualProfileCreateSaveEntity = 'individual:entity_create__save',
  IndividualProfileCreateSaveAndVerifyErrorEntity = 'individual:entity_create_error__save-verify',
  IndividualProfileCreateSaveErrorEntity = 'individual:entity_create_error__save',

  // Audit Report Events

  IndividualAuditReportView = 'individual:audit__show',
  IndividualAuditReportError = 'individual:audit_error__show',
  IndividualAuditReportGenerate = 'individual:audit_report__generate',

  IndividualAuditReportGenerateError = 'individual:audit_report_error__generate',
  IndividualAuditReportScroll = 'individual:audit_page{page}__scroll',
  IndividualAuditReportCopyRequestId = 'individual:audit_request-id__copy',

  // Profile insight events
  ProfileInsightsViewIndividual = 'individual:profile__show',
  ProfileInsightsPersonalInfoClickIndividual = 'individual:profile_side-nav-personal-info__click',
  ProfileInsightsIdentityDocumentsClickIndividual = 'individual:profile_side-nav-identity-documents__click',
  ProfileInsightsSelfieClickIndividual = 'individual:profile_side-nav-selfie__click',
  ProfileInsightsPersonalInfoViewIndividual = 'individual:profile_personal-info__show',
  ProfileInsightsIdentityDocumentsViewIndividual = 'individual:profile_identity-documents__show',
  ProfileInsightsAmlViewIndividual = 'individual:profile_aml__show',
  ProfileInsightsSelfieViewIndividual = 'individual:profile_selfie__show',
  ProfilesInsightAmlTilesClickIndividual = 'individual:profile_aml_{tiles}__click',
  ProfileInsightViewAllClickIndividual = 'individual:profile_aml-view-all__click',

  // aml result events

  AmlHistoryClickIndividual = 'individual:workflow-events_aml-view-all__click',
  AmlResultViewIndividual = 'individual:aml_alert__show',
  AmlTabViewIndividual = 'individual:aml_alert_{tab}__show',
  AmlModalViewIndividual = 'individual:aml_resolve__show',
  AmlConfirmResolveIndividual = 'individual:aml_resolve__confirm',
  AmlHistoryPageShowIndividual = 'individual:aml-history__show',
  AmlScreeningPageShowIndividual = 'individual:aml__show',
  AMLProfileSideNavClick = 'profile_side-nav-aml__click',

  // trust analyser events
  TrustAnalyserAnalysisResultEdit = 'trust-analyser:analysis-result-edit__click',
  TrustAnalyserAnalysisResultSave = 'trust-analyser:analysis-result-save__click',
  TrustAnalyserAnalysisResultShow = 'trust-analyser:analysis-result__show',
  TrustAnalyserAnalysisResultFeedback = 'trust-analyser:analysis-result__feedback',

  // custom views events
  CustomViewCreatedFromCustomSection = 'filter_advanced_custom-view__create',
  CustomViewAdvancedFilterApplyFromCustomSection = 'filter-advanced__apply',
  CustomViewSaveFromCustomSection = 'custom-view__save',

  AdvanceFilterShow = 'filter-advanced__show',
  CustomViewCreateFromSaveButtonFromCustomSection = 'entities_custom-view__create',
  CustomViewClick = 'custom-view__click',
  CustomViewSaveChanges = 'entities_custom-view__save-changes',
  CustomViewSaveNew = 'entities_custom-view__save-new',
  CustomViewDelete = 'custom-view__delete',

  // comments events
  CommentWorkflowEventExpandClick = 'individual:workflow-events_comment-expanded__click',
  CommentWorkflowEventExpand = 'individual:workflow-events_comment__expand',
  CommentWorkflowEventCollapse = 'individual:workflow-events_comment__collapse',
  CommentWorkflowEventExpandedPrevious = 'individual:workflow-events_comment-expanded__previous',
  CommentWorkflowEventExpandedNext = 'individual:workflow-events_comment-expanded__next',
  CommentWorkflowEventExpandedCollapse = 'individual:workflow-events_comment-expanded__collapse',
  CommentProfileExpand = 'individual:profile_comment__expand',
  CommentProfileCollapse = 'individual:profile_comment__collapse',
  CommentProfileExpandedCollapse = 'individual:profile_comment-expanded__collapse',
  CommentProfilePersonalInfoHover = 'individual:profile_personal-info__hover',
  CommentProfilePersonalInfoExpand = 'individual:profile_personal-info__expand',
  CommentProfilePersonalInfoCollapse = 'individual:profile_personal-info__collapse',

  CommentWorkflowAmlAlertHover = 'individual:workflow-events_aml-alert_comment__hover',
  CommentWorkflowAmlAlertExpand = 'individual:workflow-events_aml-alert_comment__expand',
  CommentWorkflowAmlAlertCollapse = 'individual:workflow-events_aml-alert_comment__collapse',

  CommentAmlHistoryAlertHover = 'individual:aml-history_alert_comment__hover',
  CommentAmlHistoryAlertExpand = 'individual:aml-history_alert_comment__expand',
  CommentAmlHistoryAlertCollapse = 'individual:aml-history_alert_comment__collapse',
  CommentAmlHistoryAlertExpandedCollapse = 'individual:aml-history__alert_comment-expanded__collapse',
  CommentAmlHistoryAlertExpandedViewBatch = 'individual:aml-history__alert_comment-expanded__view-batch',

  CommentAmlHover = 'individual:aml_comment__hover',
  CommentAmlExpand = 'individual:aml_comment__expand',
  CommentAmlCollapse = 'individual:aml_comment__collapse',
  CommentAmlExpandedCollapse = 'individual:aml_comment-expanded__collapse',

  CommentAmlAlertHover = 'individual:aml_alert_comment__hover',
  CommentAmlAlertExpand = 'individual:aml_alert_comment__expand',
  CommentAmlAlertCollapse = 'individual:aml_alert_comment__collapse',
  CommentAmlAlertExpandedCollapse = 'individual:aml_alert_comment-expanded__collapse',
}

export const TrackingEventsTypesF1 = [
  TrackingEventsTypes.FilteringEntityStatus,
  TrackingEventsTypes.FilteringIssues,
  TrackingEventsTypes.FilteringGeneral,
  TrackingEventsTypes.FilteringFeedback,
  TrackingEventsTypes.FilteringUnspecific,
]

// events with common ui for f1 and f2 but shouldn't be tracked for f1
export const TrackingEventsTypeF2Common = [
  TrackingEventsTypes.Settings,
  TrackingEventsTypes.PersonalSettings,
  TrackingEventsTypes.OrganizationSettings,
  TrackingEventsTypes.RoleManagementSettings,
  TrackingEventsTypes.UserManagementSettings,
  TrackingEventsTypes.SettingsUserListSearchUser,
  TrackingEventsTypes.SettingsUserListCreateUser,
  TrackingEventsTypes.SettingsUserListCreateUserError,
  TrackingEventsTypes.UserManagementUserTableEditUser,
  TrackingEventsTypes.UserManagementUserTableEditUserError,
  TrackingEventsTypes.UserManagementUserTableResetUser,
  TrackingEventsTypes.UserManagementUserTableResetUserError,
  TrackingEventsTypes.UserManagementUserTableLockUser,
  TrackingEventsTypes.UserManagementUserTableLockUserError,
  TrackingEventsTypes.UserManagementDeleteUserModalDeleteUser,
  TrackingEventsTypes.UserManagementDeleteUserModalDeleteUserError,
]

export enum TrackerNameTypes {
  HOTJAR = 'hotjar',
  APPCUES = 'appcues',
}

export const TrackingEventsPerTracker: Record<
  TrackerNameTypes,
  TrackingEventsTypes[]
> = {
  [TrackerNameTypes.HOTJAR]: [...Object.values(TrackingEventsTypes)],
  [TrackerNameTypes.APPCUES]: [
    TrackingEventsTypes.WorkflowEventsShowIndividual,
    TrackingEventsTypes.WorkflowEventsDocumentShow,
    TrackingEventsTypes.ProfileInsightsViewIndividual,
    TrackingEventsTypes.IndividualAuditReportView,
    TrackingEventsTypes.GlobalSearchShow,
    TrackingEventsTypes.AmlResultViewIndividual,
    TrackingEventsTypes.WorkflowAmlShowIndividual,
    TrackingEventsTypes.ProfileInsightsAmlViewIndividual,
    TrackingEventsTypes.IndividualSendLinkLinkTypeShow,
    TrackingEventsTypes.OrganisationSendLinkLinkTypeShow,
    TrackingEventsTypes.WorkflowEventsBiometricsShow,
    TrackingEventsTypes.WorkflowEventsBiometricsCompareShow,
  ],
}
