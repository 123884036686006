export enum PermissionTypes {
  // Role Management, actionGroup role_management

  ActionListFunction = 'ffportal_action_list.function',
  RolePermissionMappingFunction = 'ffportal_role_permission_mapping.function',
  RolePermissionMappingPage = 'ffportal_role_permission_mapping.page',
  RoleDeleteFunction = 'ffportal_role_delete.function',
  RoleUpdateFunction = 'ffportal_role_update.function',
  RoleEditPage = 'ffportal_role_edit.page',
  RoleCreateFunction = 'ffportal_role_create.function',
  RoleNewPage = 'ffportal_role_new.page',
  RoleListFunction = 'ffportal_role_list.function',
  RoleIndexPage = 'ffportal_role_index.page',
  RoleDetailFunction = 'ffportal_role_detail.function',

  // User Management, actionGroup user_management

  RoleUserPermissionListPage = 'ffportal_role_user_permission_list.page',
  CreditHeaderAcknowledgement = 'ffportal_credit_header_acknowledgement',

  ReportingDownloadCustomerRecords = 'ffportal_reporting_download_customer_records',
  ReportingDownload = 'ffportal_reporting_download.function',

  UserLock = 'ffportal_user_lock',
  UserEdit = 'ffportal_user_edit',
  UserAdd = 'ffportal_user_add',
  UserList = 'ffportal_user_list',
  UserIndex = 'ffportal_user_index',
  UserDetail = 'ffportal_user_detail',
  UserDelete = 'ffportal_user_delete',

  // Organisation settings, actionGroup admin

  OrganisationSettingsFetch = 'ffportal_admin_fetch_settings_data',
  OrganisationSettingsUpdate = 'ffportal_admin_update_settings_data',

  // Applicants Views, actionGroup entity_views
  ApplicantsPage = 'ffportal_allentities_index',
  ApplicantsReviewPage = 'ffportal_review_index',
  ApplicantNew = 'ffportal_applicant_new', // create new applicant, search for business

  // Onboarding
  OnboardingIndex = 'ffportal_onboarding_index',
  OnboardingIndexData = 'ffportal_onboarding_index_data',

  // Monitoring
  MonitoringIndex = 'ffportal_monitoring_index',
  MonitoringCaseManagementData = 'ffportal_monitoring_case_management_data',

  // Dashboard
  ReportingDashboard = 'ffportal_reporting_dashboard',
  SystemMonitoring = 'ffportal_dashboard_system_monitoring',

  // Blocklist
  BlocklistNew = 'ffportal_entity_flag_blacklist',
  WatchListNew = 'ffportal_entity_flag_watchlist',
  BlocklistEdit = 'ffportal_edit_blacklist_attribute',

  // User Related Permission
  UserChildView = 'ffportal_user_child_view',

  // Applicant Related Permission
  ApplicantRemoveBlacklist = 'ffportal_entity_remove_entity_blacklist',
  ApplicantProfileAllowStatusOverride = 'ffportal_applicant_allow_override_profile_status',
  ApplicantProfileStatusOverride = 'ffportal_applicant_override_profile_status',
  ApplicantTriggerExternalIDV = 'ffportal_trigger_external_idv',
  ApplicantArchiveInactive = 'ffportal_entity_set_archived_inactive',
  ApplicantRemoveWatchlist = 'ffportal_entity_remove_entity_watchlist',

  // Transaction Permission
  AmlTransactionData = 'ffportal_applicant_fetch_aml_transaction_data',
  FraudTransactionData = 'ffportal_applicant_fetch_fraud_transaction_data',
  DeviceTransactionData = 'ffportal_applicant_fetch_device_transaction_data',

  // Audit Permission
  AuditReportDownload = 'ffportal_audit_download_pdf',
  AuditReportView = 'ffportal_audit_list',

  // Facial Duplicates
  FacialDuplicatesReportFetchPermission = 'ffportal_applicant_fetch_facial_duplicates_report.function',
  FacialDuplicatesReportUpdatePermission = 'ffportal_applicant_update_facial_duplicates_report.function',

  PortalAmlBulkUpdate = 'ffportal_entity_aml_bulk_status_update',
  // Manual Idv
  ManualIdvUpdateAll = 'ffportal_applicant_midv_update',
  ManualIdvUpdatePersonalInfo = 'ffportal_applicant_update_personal_info',
  ManualIdvUpdateDocumentInfo = 'ffportal_applicant_update_document_info',
  ManualIdvManualKyc = 'ffportal_applicant_manual_kyc_update',
  ManualIdvElectronicKyc = 'ffportal_applicant_electronic_kyc_update',
  ManualIdvDeleteDocument = 'ffportal_document_delete',
  ManualIdvUploadScan = 'ffportal_applicant_midv_upload',
  Test = 'ffportal_applicant_midv_upload_test',
}
