export const applicantRelatedOrganisationsEn = {
  title: 'Related Organisations',
  subtitle: 'This entity is related to the following organisations',
  table: {
    row: {
      empty: 'No linked companies have been identified',
      error:
        'There was an error loading this list, please refresh this page in a few moments',
    },
    header: {
      name: 'Name',
      type: 'Entity Type',
      relationship: 'Relationship to Organisation',
    },
  },
}
