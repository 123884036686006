import { useMemo } from 'react'

import {
  ProcessResultManualStatusEnumAML,
  SupplementaryDataAML,
  useEntityAmlData,
} from 'entities/entity'

import { Nullable } from 'shared/typescript'

import {
  sortMatchStatus,
  SortModel,
} from '../model/individual-aml-result.model'

type Args = {
  entityId: string
  sorting?: SortModel
  workflowExecutionId: Nullable<string>
  historyPage?: boolean
}

export const useAmlPagination = ({
  entityId,
  sorting,
  workflowExecutionId,
  historyPage,
}: Args) => {
  const searchParams = new URLSearchParams(window.location.search)
  const processResultId = searchParams.get('processResultId')
  const { data } = useEntityAmlData({
    entityId,
    workflowExecutionId: workflowExecutionId ?? undefined,
    isNonValid: historyPage,
  })

  const sortedData = useMemo(() => {
    const isSorted = sorting?.[0]?.sort != null
    const sortedData = [...data.processResults]
    sortedData.sort((a, b) => {
      if (isSorted) {
        if (sorting[0].sort === 'asc') {
          ;[b, a] = [a, b]
        }

        const aSupplementaryData = a.supplementaryData as SupplementaryDataAML
        const bSupplementaryData = b.supplementaryData as SupplementaryDataAML

        if (sorting[0].field === 'matchStatus') {
          const res = sortMatchStatus(
            b.manualStatus as ProcessResultManualStatusEnumAML,
            a.manualStatus as ProcessResultManualStatusEnumAML,
            bSupplementaryData.matchData?.strength ?? 0,
            aSupplementaryData.matchData?.strength ?? 0,
          )
          return res
        }

        if (sorting[0].field === 'resolvedAt') {
          return (
            new Date(b.updatedAt ?? 0).valueOf() -
            new Date(a.updatedAt ?? 0).valueOf()
          )
        }
        return (
          (bSupplementaryData.matchData?.strength ?? 0) -
          (aSupplementaryData.matchData?.strength ?? 0)
        )
      }
      return 0
    })
    return sortedData
  }, [data, sorting])

  const currentIndex = sortedData.findIndex(
    process => process.processResultId === processResultId,
  )
  const totalData = sortedData.length

  function next() {
    if (currentIndex + 1 >= sortedData.length) return null
    return sortedData[currentIndex + 1]?.processResultId
  }

  function prev() {
    if (currentIndex - 1 < 0) return null
    return sortedData[currentIndex - 1]?.processResultId
  }

  return { currentIndex, totalData, data, next, prev }
}
