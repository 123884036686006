import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import { ENTITY_KEY } from 'entities/entity/entity.key'
import { entityEn } from 'entities/entity/locale/entity.en'
import { ServiceProfileState } from 'entities/entity/model/entity.model'

import { TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import { useValidationRules } from 'shared/validation'

import { useArchiveEntityMutation } from '../../mutation/entity-archive/entity-archive.mutation'
import {
  useEntityDataQuery,
  useFrankie2R2Customer,
} from '../../state/entity-data/entity-data.query'

type Props = {
  entityId?: string
  onSuccess: () => void
}

export function ArchiveEntity({ entityId, onSuccess }: Props) {
  const { data } = useEntityDataQuery(entityId)
  const { control, handleSubmit } = useForm<{ comment: string }>({
    mode: 'onTouched',
  })
  const [, closeOverlay] = useOverlay()
  const { xssRule } = useValidationRules()

  const { mutateAsync, isLoading } = useArchiveEntityMutation(entityId)

  const isArchived = data?.serviceProfiles?.at(0)?.state === 'ARCHIVED'

  const isFrankie2R2 = useFrankie2R2Customer()

  const t = useI18n([ENTITY_KEY], { keys: entityEn })

  const onSubmit = async ({ comment }: { comment: string }) => {
    if (isArchived)
      await mutateAsync({
        state: data.serviceProfiles?.at(0)?.workflowSummaries?.length
          ? ServiceProfileState.ACTIVE
          : ServiceProfileState.INIT,
        comment,
      })
    else
      await mutateAsync({
        state: ServiceProfileState.ARCHIVED,
        comment,
      })
    closeOverlay()
    onSuccess()
  }

  useEffect(() => {
    if (data)
      if (isArchived)
        trackingManager.track(
          data.individual?.entityType === 'INDIVIDUAL'
            ? TrackingEventsTypes.UserUnarchiveIndividual
            : TrackingEventsTypes.UserUnarchiveOrganization,
        )
      else
        trackingManager.track(
          data.individual?.entityType === 'INDIVIDUAL'
            ? TrackingEventsTypes.UserArchiveIndividual
            : TrackingEventsTypes.UserArchiveOrganization,
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.individual?.entityType])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[440px] flex flex-col gap-4"
    >
      <FrankieLoader
        loading={isLoading}
        fullscreen
        label={t('archiveEntity.updatingStatus')}
      />
      <div className="text-xl font-bold text-tertiary-grey-800">
        {t(isArchived ? 'archiveEntity.unarchive' : 'archiveEntity.archive')}
      </div>
      <p className="text-sm font-semibold">
        {t(
          isArchived
            ? 'archiveEntity.unarchiveTitle'
            : 'archiveEntity.archiveTitle',
        )}
      </p>

      {!isArchived && (
        <p className="text-sm font-normal">
          {t('archiveEntity.archiveDescription')}
        </p>
      )}

      {/* TODO: REMOVE THIS WHEN COMMENT IS ENABLED */}
      {isFrankie2R2 && (
        <TextAreaFormField
          label={t('archiveEntity.comment')}
          control={control}
          rules={{
            required: t('archiveEntity.commentError'),
            ...xssRule,
          }}
          showError
          name="comment"
          trim
          counter={500}
          maxLength={500}
          inputClassName="!min-h-[86px]"
          placeholder={t('archiveEntity.commentPlaceholder')}
        />
      )}
      <div className="flex justify-end mt-2">
        <FrankieButton onClick={closeOverlay} intent="subtle">
          {t('archiveEntity.cancel')}
        </FrankieButton>
        <FrankieButton type="submit">
          {t(
            isArchived
              ? 'archiveEntity.unarchiveCta'
              : 'archiveEntity.archiveCta',
          )}
        </FrankieButton>
      </div>
    </form>
  )
}
