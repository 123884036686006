/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { useQuery, useQueries, UseQueryOptions } from '@tanstack/react-query'

import { bffClient } from 'shared/client'
import type { ErrorResponse } from 'shared/http'

import { ApplicantRelatedOrganisationsAPI } from '../api/ApplicantRelatedOrganisationsAPI'
import type {
  Entity,
  FetchResult,
  ParentAssociation,
  NormalizedAssocation,
} from '../model/applicant-related-organisations.model'

type Queries = UseQueryOptions<Entity, ErrorResponse>

export const useFetchApplicantRelatedOrganisations = (entityId: string) => {
  const api = new ApplicantRelatedOrganisationsAPI(bffClient)

  const {
    data: parentAssociations,
    isLoading: parentLoading,
    isFetching: parentFetching,
    isError: parentIsError,
  } = useQuery<ParentAssociation, ErrorResponse>({
    enabled: !!entityId,
    queryKey: ['applicant-related-organisations', entityId],
    queryFn: async () => {
      const { data } = await api.getParentAssociations(entityId)
      return data
    },
  })

  /**
   * Normalize the data when the parent associations are fetched successfully
   * and we combine the similar parent associations into one object that represented by the parent id
   * @example
   *  const parentAssociations = {
   *    associations: [
   *      {
   *        parentId: '1',
   *        association: 'ultimate_beneficial_owner',
   *        roleDescriptions: ['Owner'],
   *      },
   *      {
   *        parentId: '1',
   *        association: 'ultimate_beneficial_owner',
   *        roleDescriptions: ['Director'],
   *      },
   *      {
   *        parentId: '2',
   *        association: 'others',
   *        roleDescriptions: [],
   *      },
   *    ]}
   *  }
   *
   *  mapped to
   *  const normalized = [
   *    { id: '1', relations: { ultimate_beneficial_owner: [ 'Owner', 'Director' ]} },
   *    { id: '2', relations: [] }
   *  ],
   */
  const normalized =
    parentAssociations?.associations?.reduce<NormalizedAssocation[]>(
      (acc, association) => {
        const existing = acc.find(n => n.id === association.parentId)
        if (existing) {
          existing.relations[association.association] = [
            ...(existing.relations[association.association] ?? []),
            ...(association.roleDescriptions ?? []),
          ]
        } else {
          acc.push({
            id: association.parentId,
            relations: {
              [association.association]: association.roleDescriptions ?? [],
            },
          })
        }
        return acc
      },
      [],
    ) ?? []

  const entities = useQueries({
    queries: normalized.map<Queries>(n => ({
      enabled: !!n.id && !parentIsError,
      queryKey: ['applicant-related-organisations', n.id],
      queryFn: async () => {
        const { data } = await api.getEntityDetails(n.id)
        return data
      },
    })),
  })

  const error = parentIsError || entities.every(({ isError }) => isError)
  const loading =
    parentFetching ||
    parentLoading ||
    entities.some(({ isLoading, isFetching }) => isLoading || isFetching)

  if (loading) {
    return { loading: true, error: false, data: [] }
  }

  if (error) {
    return { loading: false, error: true, data: [] }
  }

  const data = entities.map(({ data }) => ({
    id: data?.entityId ?? '',
    name: data?.organisationData?.registeredName ?? '',
    relations: normalized.find(n => n.id === data?.entityId)?.relations ?? {},
    type:
      data?.organisationData?.type.description ??
      data?.organisationData?.type.code ??
      '',
  }))

  return { loading: false, error: false, data }
}

export const useGetRowApplicantRelatedOrganitions = (
  data: FetchResult['data'],
) =>
  data
    .filter(row => row.name)
    .map(row => {
      const { relations } = row
      const relationNames = Object.keys(relations)

      const relationships = relationNames.map(relationName => {
        let name = relationName
        const roles = relations[relationName] ?? []

        switch (relationName) {
          case 'ultimate_beneficial_owner':
            name = 'Ultimate Beneficial Owner'
            break
          case 'officer':
            name = 'Officer'
            break
          case 'benefecialOwners':
            name = 'Others'
            if (roles.length > 0) {
              name += ` - ${roles.join(', ')}`
            }

            break
          case 'static_officer':
          case 'associatedParties':
            name = 'Associated Party'
            if (roles.length > 0) {
              name += ` - ${roles.join(', ')}`
            }

            break
          case 'shareholder':
            name = 'Share Capital - Shareholder'
            break
          default:
            break
        }

        return name
      })

      return {
        id: row.id,
        type: row.type || '-',
        name: row.name.toUpperCase(),
        relationships,
      }
    })
