import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { FrankieIcon, FrankieTextField } from 'frankify/src'

import { useHasFeatureFlag } from 'entities/session'

import { getError, validateDate } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { EMAIL_PATTERN } from 'shared/validation'

import { INDIVIDUAL_PROFILE_KEY } from '../../individual-profile.keys'
import { individualProfileEn } from '../../locale/new-profile.en'
import {
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
} from '../../model/form.model'
import { individualProfileVueMigratedQa } from '../../qa/individual-profile.qa'

export type Props = {
  form: UseFormReturn<IIndividualProfileInputs>
  isOptionalFieldRequired?: boolean
  hidePhoneAndEmail?: boolean
  testId?: { container?: string }
}

const SAFE_PATTERN =
  /^(?!.*\b(?:iframe|http|https|script|ftp|mailto|file|gopher|(?:\d{1,3}\.){3}\d{1,3})\b).*$/

export function PersonalInfo({
  form,
  isOptionalFieldRequired = true,
  hidePhoneAndEmail = false,
  testId,
}: Props) {
  const {
    register,
    watch,
    formState: { errors },
  } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const optionalFields = useHasFeatureFlag({
    givenName: ['optionalFields', 'givenName'],
    familyName: ['optionalFields', 'familyName'],
    dateOfBirth: ['optionalFields', 'dateOfBirth'],
  })
  const profileRecipeWatch = watch('recipe')
  const customerIdRegister = register(
    `${IndividualProfileInputTypes.PersonalInfo}.${IndividualProfileInputTypes.CustomerId}`,
    {
      pattern: SAFE_PATTERN,
    },
  )

  const firstNameRegister = register(
    `${IndividualProfileInputTypes.PersonalInfo}.${IndividualProfileInputTypes.FirstName}`,
    {
      required: !optionalFields.givenName,
      pattern: SAFE_PATTERN,
    },
  )

  const middleNameRegister = register(
    `${IndividualProfileInputTypes.PersonalInfo}.${IndividualProfileInputTypes.MiddleName}`,
    {
      pattern: SAFE_PATTERN,
    },
  )

  const lastNameRegister = register(
    `${IndividualProfileInputTypes.PersonalInfo}.${IndividualProfileInputTypes.LastName}`,
    {
      required: !optionalFields.familyName,
      pattern: SAFE_PATTERN,
    },
  )

  const dobRegister = register(
    `${IndividualProfileInputTypes.PersonalInfo}.${IndividualProfileInputTypes.Dob}`,
    {
      required:
        profileRecipeWatch !== 'beneficiary' && !optionalFields.dateOfBirth,
      validate: {
        dob: value => validateDate(value, true),
      },
      pattern: SAFE_PATTERN,
    },
  )

  const phoneNumberRegister = register(
    `${IndividualProfileInputTypes.PersonalInfo}.${IndividualProfileInputTypes.PhoneNumber}`,
    {
      validate: (value: string) =>
        !value ||
        (`${value}` === `${value}`.trim() &&
          `${value}`.length > 5 &&
          `${value}`.length <= 20),
    },
  )

  const emailRegister = register(
    `${IndividualProfileInputTypes.PersonalInfo}.${IndividualProfileInputTypes.Email}`,
    { pattern: EMAIL_PATTERN },
  )

  return (
    <div data-qa={testId?.container} className="">
      {isOptionalFieldRequired && (
        <div className="text-lg mt-6 mb-[14px] font-bold">
          {t('personalInfo')}
        </div>
      )}

      {!isOptionalFieldRequired && (
        <div className="flex justify-start items-center">
          <FrankieIcon name="mdiAccountOutline" className="mr-3" />
          <div className="font-bold">{t('profileForm.name')}</div>
        </div>
      )}
      <div className="flex flex-wrap">
        {isOptionalFieldRequired && (
          <FrankieTextField
            className="mt-4  mb-2 basis-1/4"
            label={`${t('profileForm.customerId')} ${t('optional')}`}
            error={
              !!errors[IndividualProfileInputTypes.PersonalInfo]?.[
                IndividualProfileInputTypes.CustomerId
              ]
            }
            errorText={
              errors[IndividualProfileInputTypes.PersonalInfo]?.[
                IndividualProfileInputTypes.CustomerId
              ]
                ? t('profileForm.errors.unacceptableText')
                : ''
            }
            {...customerIdRegister}
            placeholder={t('profileForm.customerIdPlaceHolder')}
            testId={{ input: individualProfileVueMigratedQa.customerId }}
          />
        )}
        <div className="basis-[100%] flex-wrap flex gap-[20px]">
          <FrankieTextField
            className="mt-4 mb-2 basis-1/4"
            label={`${t('profileForm.firstName')}`}
            error={
              !!errors[IndividualProfileInputTypes.PersonalInfo]?.[
                IndividualProfileInputTypes.FirstName
              ]
            }
            errorText={
              errors[IndividualProfileInputTypes.PersonalInfo]?.[
                IndividualProfileInputTypes.FirstName
              ]
                ? t('profileForm.errors.unacceptableText')
                : ''
            }
            {...firstNameRegister}
            placeholder={t('profileForm.firstNamePlaceholder')}
            testId={{ input: individualProfileVueMigratedQa.firstName }}
          />
          <FrankieTextField
            className="mt-4 mb-2 basis-1/4"
            label={`${t('profileForm.middleName')} ${t('optional')}`}
            error={
              !!errors[IndividualProfileInputTypes.PersonalInfo]?.[
                IndividualProfileInputTypes.MiddleName
              ]
            }
            errorText={
              errors[IndividualProfileInputTypes.PersonalInfo]?.[
                IndividualProfileInputTypes.MiddleName
              ]
                ? t('profileForm.errors.unacceptableText')
                : ''
            }
            {...middleNameRegister}
            placeholder={t('profileForm.middleNamePlaceholder')}
            testId={{ input: individualProfileVueMigratedQa.middleName }}
          />
          <FrankieTextField
            className="mt-4 mb-2 basis-1/4"
            label={`${t('profileForm.lastName')}`}
            error={
              !!errors[IndividualProfileInputTypes.PersonalInfo]?.[
                IndividualProfileInputTypes.LastName
              ]
            }
            errorText={
              errors[IndividualProfileInputTypes.PersonalInfo]?.[
                IndividualProfileInputTypes.LastName
              ]
                ? t('profileForm.errors.unacceptableText')
                : ''
            }
            {...lastNameRegister}
            placeholder={t('profileForm.lastNamePlaceHolder')}
            testId={{ input: individualProfileVueMigratedQa.lastName }}
          />
        </div>
        {!isOptionalFieldRequired && (
          <div className="flex justify-start items-center mt-6 basis-[100%]">
            <FrankieIcon name="mdiCalendarRange" className="mr-3" />
            <div className="font-bold">{t('profileForm.dob')}</div>
          </div>
        )}
        <FrankieTextField
          type="date"
          className="mt-4 mb-2 basis-1/4"
          label={`${t('profileForm.dob')}`}
          {...dobRegister}
          placeholder={t('profileForm.dobPlaceholder')}
          error={
            !!errors[IndividualProfileInputTypes.PersonalInfo]?.[
              IndividualProfileInputTypes.Dob
            ]
          }
          testId={{ input: individualProfileVueMigratedQa.dob }}
        />
        {isOptionalFieldRequired && !hidePhoneAndEmail && (
          <div className="basis-[100%] flex-wrap flex gap-[20px] ">
            <FrankieTextField
              className="mt-4 mb-2 basis-1/4"
              label={`${t('profileForm.phoneNumber')} ${t('optional')}`}
              {...phoneNumberRegister}
              error={!!getError(phoneNumberRegister.name, errors)}
              placeholder={t('profileForm.phoneNumberPlaceholder')}
              testId={{ input: individualProfileVueMigratedQa.mobileNumber }}
            />
            <FrankieTextField
              className="mt-4 mb-2 basis-1/4"
              label={`${t('profileForm.email')} ${t('optional')}`}
              placeholder={t('profileForm.emailPlaceholder')}
              {...emailRegister}
              error={!!getError(emailRegister.name, errors)}
              testId={{ input: individualProfileVueMigratedQa.emailAddress }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
