import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieButton, FrankieLoader } from 'frankify/src'

import {
  ExecuteWorkflowPayload,
  useGetWorkflowEventsData,
} from 'entities/entity'
import { useWorkflowListQuery } from 'entities/workflow'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { useValidationRules } from 'shared/validation'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../locale/individual-profile-insight.en'
import { useExecuteWorkflowMutation } from '../../mutation/execute-workflow.mutation'

type Props = {
  entityId: string
}

export function IndividualProfileVerify({ entityId }: Props) {
  const { mutate, isLoading, isSuccess } = useExecuteWorkflowMutation({
    entityId,
  })

  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })

  const { data: workflows } = useWorkflowListQuery()

  const { data } = useGetWorkflowEventsData({ entityId })

  const [, closeOverlay] = useOverlay()

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<ExecuteWorkflowPayload>({
    mode: 'onTouched',
  })

  const { xssRule } = useValidationRules()

  const profileOptions = workflows?.map(workflow => ({
    label: workflow.workflowName,
    value: workflow.workflowName,
  }))

  useEffect(() => {
    if (isSuccess) {
      closeOverlay()
    }
  }, [isSuccess])

  useEffect(() => {
    if (data?.currentWorkflowName) {
      setValue('workflowName', data.currentWorkflowName)
    }
  }, [data, setValue])

  const onSubmit = (data: ExecuteWorkflowPayload) => {
    mutate(data)
  }
  return (
    <>
      <FrankieLoader
        label={t('banner.modal.loading')}
        size="md"
        className="z-20"
        fullscreen
        loading={isLoading}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-[440px] flex flex-col gap-4"
      >
        <div className="text-xl font-bold text-tertiary-grey-800">
          {t('banner.modal.buttonVerify')}
        </div>

        <p className="text-mono-black">{t('banner.modal.verifyDescription')}</p>

        <SelectFormField
          className="mt-4 mb-2  basis-1/4"
          inputClassName="h-[37px]"
          control={control}
          name="workflowName"
          placeholder={t('banner.modal.selectWorkflow')}
          label={t('banner.modal.selectWorkflow')}
          options={profileOptions || []}
          rules={{ required: true }}
          showErrorText
        />

        <TextAreaFormField
          label={t('banner.modal.commentLabel')}
          control={control}
          rules={{
            required: t('banner.modal.commentError'),
            ...xssRule,
          }}
          showError
          name="comment"
          trim
          maxLength={500}
          inputClassName="!min-h-[86px]"
          placeholder={t('banner.modal.commentPlaceholder')}
        />
        <div className="flex justify-end mt-2 gap-5">
          <FrankieButton onClick={closeOverlay} intent="subtle">
            {t('banner.modal.cancel')}
          </FrankieButton>
          <FrankieButton disabled={!isValid} type="submit">
            {t('banner.modal.runWorkflow')}
          </FrankieButton>
        </div>
      </form>
    </>
  )
}
