import { useMutation } from '@tanstack/react-query'

import { amlResultApi } from 'entities/entity/api/aml.api'
import { ENTITY_KEY } from 'entities/entity/entity.key'
import { entityEn } from 'entities/entity/locale/entity.en'
import { AmlStatusUpdateProps } from 'entities/entity/model/aml.model'
import { useEntityAmlData } from 'entities/entity/state/entity-aml/entity-aml.state'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

type Args = {
  entityId: string
  onSuccess: () => void
}

export const useUpdateAmlStatus = ({ entityId, onSuccess }: Args) => {
  const t = useI18n([ENTITY_KEY], { keys: entityEn })
  const { refetch } = useEntityAmlData({ entityId })
  return useMutation({
    mutationKey: ['updateAmlStatus'],
    mutationFn: async (data: AmlStatusUpdateProps) => {
      const res = await amlResultApi.updateAmlStatus(entityId, data)
      return res.data
    },
    onSuccess: (_, payload) => {
      void refetch()
      notification.success(
        t('amlStatusForm.success', { count: payload.processResults.length }),
      )

      trackingManager.track(TrackingEventsTypes.AmlConfirmResolveIndividual)
      onSuccess()
    },
    onError: (_, payload) => {
      notification.error(
        t('amlStatusForm.error', { count: payload.processResults.length }),
      )
    },
  })
}
