import {
  countryPhoneCodeList,
  getCountryNameByAlpha3Code,
} from 'entities/country'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { I18nKeys } from 'shared/i18n'

import { extractLongForm } from './entity-data.model'
import {
  AddressStatusEnum,
  AddressTypeEnum,
  DocumentType,
  DocumentInformation,
  Individual,
} from './entity.model'
import { entityEn } from '../locale/entity.en'

export interface IDocsToBeDisplayedType extends DocumentInformation {
  label: ILabelAndSources[]
  extraData: ILabelAndSources[]
}

export interface ILabelAndSources {
  label: string
  value?: string | undefined
  sortPos?: number
  sources?: string[]
}

type GetPersonalInfoLabelDataProps = {
  individual?: Individual
  t: (key: I18nKeys<typeof entityEn>) => string
  singleAddress?: boolean
}

const getFullName = (individual: Individual) => {
  const { displayName, givenName, middleName, familyName } =
    individual.name ?? {}
  return (
    displayName ??
    `${givenName ?? ''} ${middleName ?? ''} ${familyName ?? ''}`.trim()
  )
}

export const getProfileInfoWithLabelDataF2R2 = ({
  t,
  individual,
  singleAddress = false,
}: GetPersonalInfoLabelDataProps) => {
  const fullName = getFullName(individual ?? {})

  const email = individual?.emailAddresses?.[0]?.email

  const phoneNumber = individual?.phoneNumbers?.[0]

  const countryCode = countryPhoneCodeList.find(
    i => i.code === phoneNumber?.country,
  )?.value

  const phone = `${countryCode ? `+${countryCode}` : ''}${
    phoneNumber?.number ?? ''
  }`

  const additionalAddress = singleAddress
    ? []
    : [
        {
          label: t('personalInfo.previousAddress'),
          value: extractLongForm(
            individual?.addresses?.find(
              address =>
                address.type === AddressTypeEnum.RESIDENTIAL &&
                address.status === AddressStatusEnum.PREVIOUS,
            ),
          ),
        },
        {
          label: t('personalInfo.mailingAddress'),
          value: extractLongForm(
            individual?.addresses?.find(
              address => address.type === AddressTypeEnum.POSTAL,
            ),
          ),
        },
        { label: t('personalInfo.phoneNumber'), value: phone },
        { label: t('personalInfo.email'), value: email },
      ]

  const profileInfo: ILabelAndSources[] = [
    {
      label: t(singleAddress ? 'personalInfo.fullName' : 'personalInfo.name'),
      value: fullName,
    },
    {
      label: t('personalInfo.dob'),
      value:
        individual?.dateOfBirth?.normalized &&
        formatDate(
          individual.dateOfBirth.normalized,
          DateFormatTypes.ShortDate,
        ),
    },
    {
      label: t('personalInfo.currentAddress'),
      value: extractLongForm(
        individual?.addresses?.find(
          address =>
            address.type === AddressTypeEnum.RESIDENTIAL &&
            address.status === AddressStatusEnum.CURRENT,
        ),
      ),
    },

    ...additionalAddress,
  ]

  return profileInfo
}

type GetDocumentWithLabelDataProps = GetPersonalInfoLabelDataProps & {
  documents?: DocumentInformation[]
}

export const getDocumentWithLabelDataF2R2 = ({
  documents,
  individual,
  t,
}: GetDocumentWithLabelDataProps) => {
  const profileInfo: ILabelAndSources[] = getProfileInfoWithLabelDataF2R2({
    individual,
    t,
    singleAddress: true,
  })

  const docsToBeDisplayed: IDocsToBeDisplayedType[] | undefined = documents
    ?.sort((a, b) => a.type.charCodeAt(0) - b.type.charCodeAt(0))
    // eslint-disable-next-line complexity
    ?.map(document => {
      let label: ILabelAndSources[] = []
      let extraData: ILabelAndSources[] = []
      switch (document.type) {
        case DocumentType.DRIVERS_LICENSE:
          label = [
            { ...profileInfo[0] },
            {
              label: t('labels.driverLicense.licenseNumber'),
              value: document.primaryIdentifier,
            },
            {
              label: t('labels.driverLicense.country'),
              value: document.country,
            },
            { ...profileInfo[1] },

            {
              label: t('labels.driverLicense.cardNumber'),
              value: document.secondaryIdentifier,
            },
            {
              label: t('labels.driverLicense.state'),
              value: document.subdivision,
            },
          ]
          extraData = [
            ...profileInfo,
            {
              label: t('labels.driverLicense.licenseNumber'),
              value: document.primaryIdentifier,
            },
            {
              label: 'Country of issueing',
              value: document.country,
            },

            {
              label: t('labels.driverLicense.cardNumber'),
              value: document.secondaryIdentifier,
            },
            {
              label: t('labels.driverLicense.state'),
              value: document.subdivision,
            },
          ]
          break
        case DocumentType.PASSPORT:
          label = [
            { ...profileInfo[0] },
            {
              label: t('labels.passport.passport'),
              value: document.primaryIdentifier,
            },
            {
              label: t('labels.passport.country'),
              value: getCountryNameByAlpha3Code(document.country),
            },
            {
              label: t('personalInfo.dob'),
              value:
                individual?.dateOfBirth?.normalized &&
                formatDate(
                  individual.dateOfBirth.normalized,
                  DateFormatTypes.ShortDate,
                ),
            },
            {
              label: t('labels.passport.gender'),
              value: individual?.gender?.gender,
            },
          ]
          extraData = [
            { ...profileInfo[0] },
            {
              label: t('personalInfo.dob'),
              value:
                individual?.dateOfBirth?.normalized &&
                formatDate(
                  individual.dateOfBirth.normalized,
                  DateFormatTypes.ShortDate,
                ),
            },
            {
              label: t('labels.passport.passport'),
              value: document.primaryIdentifier,
            },
            {
              label: t('labels.passport.country'),
              value: getCountryNameByAlpha3Code(document.country),
            },

            {
              label: t('labels.passport.gender'),
              value: individual?.gender?.gender,
            },
          ]
          break
        case DocumentType.NATIONAL_HEALTH_ID:
          label = [
            {
              label: t('labels.medicareCard.displayName'),
              value: document.supplementaryData?.nameOnCardLine1 || '-',
            },
            {
              label: t('labels.medicareCard.cardNumber'),
              value: document.primaryIdentifier,
            },
            {
              label: t('labels.medicareCard.country'),
              value: getCountryNameByAlpha3Code(document.country),
            },
            {
              label: t('labels.medicareCard.position'),
              value: document.supplementaryData?.reference || '-',
            },
            {
              label: t('labels.medicareCard.expiry'),
              value:
                document.expiryDate?.normalized &&
                formatDate(
                  document.expiryDate.normalized,
                  document.subtype === 'G'
                    ? DateFormatTypes.ShortDateNoDay
                    : DateFormatTypes.ShortDate,
                ),
            },

            {
              label: t('labels.medicareCard.state'),
              value: document.subdivision,
            },
            {
              label: t('labels.medicareCard.color'),
              value: t(
                `medicareCardColor.${document.subtype as 'G' | 'B' | 'Y'}`,
              ),
            },
          ]
          extraData = [
            {
              label: t('labels.medicareCard.displayName'),
              value: document.supplementaryData?.nameOnCardLine1 || '-',
            },
            {
              label: t('labels.medicareCard.cardNumber'),
              value: document.primaryIdentifier,
            },
            {
              label: t('labels.medicareCard.country'),
              value: getCountryNameByAlpha3Code(document.country),
            },
            {
              label: t('labels.medicareCard.position'),
              value: document.supplementaryData?.reference || '-',
            },
            {
              label: t('labels.medicareCard.expiry'),
              value:
                document.expiryDate?.normalized &&
                formatDate(
                  document.expiryDate.normalized,
                  document.subtype === 'G'
                    ? DateFormatTypes.ShortDateNoDay
                    : DateFormatTypes.ShortDate,
                ),
            },

            {
              label: t('labels.medicareCard.state'),
              value: document.subdivision,
            },
            {
              label: t('labels.medicareCard.color'),
              value: t(
                `medicareCardColor.${document.subtype as 'G' | 'B' | 'Y'}`,
              ),
            },
          ]
          break
        default:
          break
      }
      return {
        ...document,
        label,
        extraData,
      }
    })
  return docsToBeDisplayed
}
