import React from 'react'

import { useForm } from 'react-hook-form'

import {
  FrankieTextField,
  useFrankieMenu,
  FrankieIcon,
  FrankieTextarea,
  FrankieButton,
  FrankieLoader,
} from 'frankify/src'

import { MONITORING_OVERVIEW_KEY } from 'features/monitoring-overview/monitoring-overview.key'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { trackingManager, TrackingEventsTypes } from 'shared/tracking'

import { StatusType } from '../../model/applicant.model'
import { useApplicantUpdateStatus } from '../../state/update-status-mutation/applicant-update-status.mutation'

enum FormFieldTypes {
  Comment = 'comment',
  Status = 'state',
}

type FormFields = {
  comment: string
  state: StatusType
}

type Props = {
  applicantId: string
  disabled?: boolean
  onClose: () => void
  options: { title: string; value: string }[]
}

export function UpdateStatusModal({
  applicantId,
  options,
  disabled,
  onClose,
}: Props) {
  const t = useI18n(MONITORING_OVERVIEW_KEY)

  const { parentRef, isOpen, handleOpen, handleToggle, handleClose } =
    useFrankieMenu()

  const handleChange = () => {
    handleOpen()
  }

  const { mutateAsync: updateApplicantStatus, isLoading: isUpdatingStatus } =
    useApplicantUpdateStatus()

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      comment: '',
      state: 'pass',
    },
  })

  const formWatch = watch()

  register(FormFieldTypes.Status, {
    required: {
      value: true,
      message: 'err',
    },
  })

  register(FormFieldTypes.Comment, {
    required: {
      value: true,
      message: 'err',
    },
  })

  const handleClickOptions = (value: string) => {
    setValue(FormFieldTypes.Status, value as StatusType)
    void trigger(FormFieldTypes.Status)
    handleClose()
  }

  const handleChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(FormFieldTypes.Comment, e.target.value)
    void trigger(FormFieldTypes.Comment)
  }

  const handleChangeStatus = async () => {
    try {
      trackingManager.track(TrackingEventsTypes.OrganizationManualStatusUpdate)
      await updateApplicantStatus({
        applicantId,
        status: formWatch[FormFieldTypes.Status],
        comment: formWatch[FormFieldTypes.Comment],
      })
      onClose()
      window.location.reload()
    } catch (error) {
      notification.error(t('errorOnViewReport'))
    }
  }

  const displayValue = options.find(
    opt => opt.value === formWatch[FormFieldTypes.Status],
  )?.title

  return (
    <FrankieLoader loading={isUpdatingStatus}>
      <span className="block text-tertiary-grey-800 text-xl font-bold">
        {t('changeStatusModal.title')}
      </span>
      <span className="block text-tertiary-grey-700 text-sm font-normal mt-3">
        {t('changeStatusModal.subtitle')}
      </span>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <div className="relative mt-6" ref={parentRef}>
        <span className="text-sm font-medium">
          {t('changeStatusModal.profileStatusSelect')}
        </span>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <FrankieTextField
            className="caret-transparent cursor-pointer"
            inputClassName="pr-[30px] text-tertiary-grey-700 focus-visible:text-tertiary-grey-500"
            value={displayValue}
            onChange={handleChange}
            disabled={disabled}
            size="sm"
            onClick={handleToggle}
          />
          {!disabled && (
            <FrankieIcon
              className="absolute bottom-2.5 right-4 text-tertiary-grey-700 cursor-pointer"
              name={isOpen ? 'mdiChevronUp' : 'mdiChevronDown'}
              size="xs"
            />
          )}
        </label>
        <div className="relative">
          {isOpen && (
            <ul className="absolute z-10 bg-mono-white shadow-md rounded-sm top-1 w-full max-h-[193px] py-1 overflow-y-auto scrollbar-sm">
              {options.map(option => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  value={option.title}
                  key={option.title}
                  onClick={() => handleClickOptions(option.value)}
                  onKeyDown={() => handleClickOptions(option.value)}
                  className="text-sm text-tertiary-grey-800 py-2 px-4 cursor-pointer"
                >
                  {option.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div>
        <span className="inline-block mt-4 text-sm font-medium">
          {t('changeStatusModal.comments')}
        </span>
        <FrankieTextarea
          placeholder={t('changeStatusModal.commentsPlaceholder')}
          value={formWatch[FormFieldTypes.Comment]}
          onChange={handleChangeComment}
          inputClassName="mt-2 w-full min-h-[112px] !p-1 resize-none outline-none block rounded-sm border border-tertiary-grey-300 border-solid focus-visible:border-primary"
        />
      </div>
      <div className="flex flex-row justify-end mt-6">
        <FrankieButton intent="subtle" onClick={onClose}>
          {t('changeStatusModal.cancelButton')}
        </FrankieButton>
        <FrankieButton
          intent="primary"
          className="ml-2"
          disabled={!isValid}
          onClick={handleChangeStatus}
        >
          {t('changeStatusModal.changeStatusButton')}
        </FrankieButton>
      </div>
    </FrankieLoader>
  )
}
