import React, { useEffect, useState } from 'react'

import {
  DataGridPro,
  GridColDef,
  GridFilterPanel,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortItem,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { FrankieButton } from 'frankify/src'

import {
  CommentToolTip,
  CommentsTypes,
  useCommentBoxVisibility,
} from 'entities/comments'
import { AmlStatusChangeForm } from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useApplicantRoute, useRouteMatcher } from 'entities/routing'
import { useHasPermission, useSessionQuery } from 'entities/session'
import { getUserList } from 'entities/user'

import { WithProps } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import {
  AvatarCell,
  BadgeCell,
  BadgeListCell,
  DateCell,
  NameCell,
} from 'shared/tables-common'

import { FilterComponent } from './individual-aml-filter/individual-aml-filter-component/individual-aml-filter-component'
import { INDIVIDUAL_AML_SCREENING_KEY } from '../../individual-aml-screening.key'
import { individualAmlScreeningEn } from '../../locale/individual-aml-screening.en'
import {
  AmlFilters,
  amlIssuesToI18n,
  amlKeys,
  getMatchStatusBadge,
  sortMatchStatus,
} from '../../model/individual-aml-screening/individual-aml-screening.model'
import { individualAmlScreeningDataGridQa } from '../../qa/individul-aml-screening.qa'
import { useAmlPRORowData } from '../../state/individual-aml-screening.state/individual-aml-screening.state'
import { IndividualAmlNoRowPlaceholder } from '../individual-aml-no-row-placeholder/individual-aml-no-row-placeholder'
import {
  EmptyOverlay,
  MatchStrengthCell,
  PaginationWithPageSize,
  RenderHeaderCellWithPagination,
  SortModel,
  TableCheckbox,
} from '../individual-aml-screening-data-grid-helper/individual-aml-screening-data-grid-helper'

const headerClassName = (lastCol?: boolean) =>
  `!p-0 text-xs ${
    lastCol ? '' : '!pr-4'
  } !py-2 !h-auto !whitespace-break-spaces font-medium uppercase bg-tertiary-grey-50 text-tertiary-grey-500 !outline-none`
const cellClassName = '!outline-none'

type Props = {
  entityId: string
  workflowExecutionId?: string
  defaultOffset?: number
  historyPage?: boolean
  dataVirtualization?: boolean
}

// eslint-disable-next-line complexity
export function IndividualAmlScreeningDataGrid({
  entityId,
  workflowExecutionId,
  defaultOffset,
  historyPage = false,
  dataVirtualization = false,
}: Props) {
  const navigate = useNavigate()

  const apiRef = useGridApiRef()
  const { canUpdateAlertPermission } = useHasPermission({
    canUpdateAlertPermission: PermissionTypes.PortalAmlBulkUpdate,
  })

  const canUpdateAlert = canUpdateAlertPermission && !historyPage

  const { generateRoute } = useApplicantRoute()
  const isWorkflowTab = useRouteMatcher({
    pathKey: 'applicantProfileWorkflowEvent',
    entityId,
  })
  // 640
  const TABLE_MIN_WIDTH = 699 + (workflowExecutionId ? 100 : 0)

  const [sortModel, setSortModel] = useState<SortModel>([
    { field: 'matchStatus', sort: 'desc' },
  ])

  const { data: pageData } = useSessionQuery()
  const userList = getUserList(pageData)

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: defaultOffset ?? 5,
    page: 0,
  })

  const { state } = useLocation()

  const commentTypeForAml = () => {
    if (isWorkflowTab) {
      return CommentsTypes.AML
    }

    if (historyPage) {
      return CommentsTypes.AMLHISTORY
    }
    return CommentsTypes.AMLSCREENING
  }

  const defaultFilters: Partial<AmlFilters> | undefined = state

  const { control, reset, watch } = useForm<AmlFilters>({
    defaultValues: {
      issues: [],
      amlMatch: [],
      matchStatus: [],
      workflow: [],
    },
  })

  useEffect(() => {
    if (defaultFilters) {
      reset(
        {
          issues: defaultFilters.issues ?? [],
          amlMatch: defaultFilters.amlMatch ?? [],
          matchStatus: defaultFilters.matchStatus ?? [],
          workflow: defaultFilters.workflow ?? [],
        },
        { keepDefaultValues: true },
      )
    }
  }, [reset, defaultFilters])

  const filters = watch()
  const { rows } = useAmlPRORowData({
    entityId,
    workflowExecutionId,
    filters,
    isNonValid: historyPage,
  })

  const t = useI18n([INDIVIDUAL_AML_SCREENING_KEY], {
    keys: individualAmlScreeningEn,
  })

  const { data: commentViz } = useCommentBoxVisibility({ entityId })

  const isComment = commentViz?.COMMENT_AML ? 'comment' : 'no_comment'

  const columns: GridColDef<(typeof rows)[0]>[] = [
    {
      field: 'name',
      headerClassName: `${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        !canUpdateAlert ? `!pl-2 ${headerClassName()}` : headerClassName()
      } `,
      headerName: t('headers.amlMatch'),
      minWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: params => {
        const { row } = params
        const { name, countries } = row
        return (
          <NameCell
            title={name ?? ''}
            subtitle={countries?.join(' ') ?? ''}
            className={classNames(
              canUpdateAlert ? '!pl-0' : '!pl-2',
              '[&_*]:!font-normal [&_*]:!ml-0',
            )}
          />
        )
      },
    },
    {
      field: 'matchStatus',
      headerName: t('headers.matchStatus'),
      minWidth: 100,
      flex: 100 / TABLE_MIN_WIDTH,
      maxWidth: 130,
      sortable: true,
      renderHeader: WithProps(RenderHeaderCellWithPagination, { sortModel }),
      renderCell: params => (
        <BadgeCell
          {...getMatchStatusBadge(t, params.row.matchStatus)}
          className="!pl-0 !whitespace-break-spaces"
        />
      ),
      sortComparator: sortMatchStatus,
    },
    {
      field: 'issues',
      headerName: t('headers.issues'),
      minWidth: 120,
      flex: 120 / TABLE_MIN_WIDTH,
      renderCell: params => (
        <BadgeListCell
          badges={params.row.issues.map(i => t(amlIssuesToI18n[i]))}
          className="!pl-0"
        />
      ),
    },
    ...(!workflowExecutionId && !historyPage
      ? [
          {
            field: 'workflow',
            headerName: t('headers.workflow'),
            minWidth: 100,
            flex: 100 / TABLE_MIN_WIDTH,
          },
        ]
      : []),
    {
      field: 'matchStrength',
      headerName: t('headers.matchStrength'),
      minWidth: 100,
      flex: 100 / TABLE_MIN_WIDTH,
      maxWidth: 150,
      sortable: true,
      renderHeader: WithProps(RenderHeaderCellWithPagination, { sortModel }),
      renderCell: MatchStrengthCell,
    },
    {
      field: 'resolvedAt',
      headerName: t('headers.update'),
      minWidth: 100,
      flex: 100 / TABLE_MIN_WIDTH,
      maxWidth: 100,
      sortable: true,

      renderHeader: WithProps(RenderHeaderCellWithPagination, { sortModel }),
      renderCell: params => (
        <DateCell date={params.row.resolvedAt ?? ''} className="!pl-0" />
      ),
    },

    {
      field: 'resolvedBy',
      headerName: t('headers.resolvedBy'),
      minWidth: 89,
      flex: 89 / TABLE_MIN_WIDTH,
      maxWidth: 140,
      headerClassName: `${headerClassName()} !pl-4 text-center [&_.MuiDataGrid-columnHeaderTitleContainer]:justify-center`,

      renderCell: params => {
        const userFromList = userList.find(
          user => user.email === params.row.resolvedBy,
        )
        return <AvatarCell name={userFromList?.realname} />
      },
    },

    {
      field: 'comments',
      headerName: t('headers.comments'),
      minWidth: 80,
      renderCell: (params: { row: { id: string | undefined } }) => (
        <CommentToolTip
          className="w-full h-full flex justify-center items-center mr-10"
          commentType={commentTypeForAml()}
          entityId={entityId}
          processId={params.row.id}
          logoClassName="!text-tertiary-grey-400"
        />
      ),
    },
  ]

  const getRowClassName = () => 'hover:bg-tertiary-grey-200 !cursor-pointer'
  const [createOverlay, closeOverlay] = useOverlay()

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])

  const handleUpdateAlert = () => {
    createOverlay(
      <AmlStatusChangeForm
        closeOverlay={() => {
          setSelectedRows([])
          closeOverlay()
        }}
        restProcessResultIds={rows
          .map(row => row.id)
          .filter(id => !selectedRows.includes(id))}
        processResultId={selectedRows as string[]}
        entityId={entityId}
      />,
      {
        closeButtonClassName: '!top-6 !right-5',
        className: '!p-[20px]',
      },
    )
  }
  const handleSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection)
  }

  const workflowTab = useRouteMatcher({
    pathKey: 'applicantProfileWorkflowEvent',
    entityId,
  })

  const handleSelectAllClick = () => {
    if (selectedRows.length === rows.length) {
      setSelectedRows([])
    } else {
      const allRowIds = rows.map(row => row.id)
      setSelectedRows(allRowIds as GridRowSelectionModel)
    }
  }

  const onRowClick = ({ row }: GridRowParams<(typeof rows)[0]>) => {
    const urlSearch = new URLSearchParams()
    if (row.id) {
      urlSearch.append('processResultId', row.id)
    }
    if (workflowExecutionId) {
      urlSearch.append('workflowExecutionId', workflowExecutionId)
    }
    if (historyPage) {
      urlSearch.append('historyPage', 'true')
    }
    urlSearch.append('workflow', workflowTab ? 'true' : 'false')

    navigate(
      `${generateRoute({
        routeKey: 'resolveAmlIssue',
        overrideParams: { entityId },
      })}?${urlSearch.toString()}`,
      { state: { sortModel } },
    )
  }
  const noFilter = Object.values(filters).every(filter => filter.length === 0)

  const isSelectAll = selectedRows.length === rows.length

  const [isAllSelectedOnPage, setIsAllSelectedOnPage] = useState({
    count: 0,
    isSelected: false,
  })

  useEffect(() => {
    const currentRowIds =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (apiRef.current?.getSortedRowIds &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        apiRef.current
          .getSortedRowIds()
          .slice(
            paginationModel.page * paginationModel.pageSize,
            (paginationModel.page + 1) * paginationModel.pageSize,
          )) ||
      []

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (apiRef.current) {
      const isAllSelected = {
        isSelected:
          !!selectedRows.length &&
          !!currentRowIds.every(row => selectedRows.find(id => id === row)),
        count: currentRowIds.length || 0,
      }
      if (
        isAllSelected.count !== isAllSelectedOnPage.count ||
        isAllSelected.isSelected !== isAllSelectedOnPage.isSelected
      ) {
        setIsAllSelectedOnPage(isAllSelected)
      }
    }
  }, [selectedRows, filters, sortModel, paginationModel, apiRef])

  return (
    <div className="w-full flex flex-col gap-4 h-full">
      <div className="flex flex-col gap-4 grow-0">
        <div className="font-semibold text-tertiary-grey-800 leading-[24px] text-left flex flex-col gap-1">
          <span className="text-md">{t('returnedResult')}</span>
          {selectedRows.length > 0 && !isAllSelectedOnPage.isSelected && (
            <span className="text-tertiary-grey-500 leading-4 text-xs mb-2">
              {selectedRows.length === 1
                ? t('dataGrid.selectedRowText_one', {
                    amlCount: selectedRows.length,
                  })
                : t('dataGrid.selectedRowText_other', {
                    amlCount: selectedRows.length,
                  })}
            </span>
          )}

          {noFilter && rows.length === 0 ? (
            <div className="font-normal text-tertiary-grey-700">
              {t('noReturnedResult')}
            </div>
          ) : (
            <div className="flex justify-between">
              <div className="flex gap-2 grow-0">
                {amlKeys.map(
                  amlKey =>
                    (workflowExecutionId || amlKey !== 'amlMatch') && (
                      <FilterComponent
                        key={amlKey}
                        amlKey={amlKey}
                        name={amlKey}
                        control={control}
                        watch={watch}
                        show={
                          amlKey !== 'workflow' ||
                          (!workflowExecutionId && !historyPage)
                        }
                        testId={{
                          amlFilter: individualAmlScreeningDataGridQa.filter,
                          amlContainer:
                            individualAmlScreeningDataGridQa.filterContainer,
                          btn: individualAmlScreeningDataGridQa.popoverBtn,
                        }}
                      />
                    ),
                )}
                {!noFilter && (
                  <FrankieButton
                    noStyles
                    className="py-2 px-3 text-tertiary-grey-700 font-semibold text-xs leading-4"
                    onClick={() => reset()}
                  >
                    {t('clearFilters')}
                  </FrankieButton>
                )}
              </div>
              {canUpdateAlert && (
                <FrankieButton
                  intent="primary"
                  noStyles
                  className={`${
                    !selectedRows.length
                      ? 'text-tertiary-grey-400'
                      : 'text-primary-800'
                  } font-semibold text-sm leading-5 !truncate ml-2`}
                  disabled={!selectedRows.length}
                  onClick={handleUpdateAlert}
                >
                  {t('updateAlert')}
                </FrankieButton>
              )}
            </div>
          )}
        </div>

        {isAllSelectedOnPage.isSelected && rows.length ? (
          <div className="bg-primary-container text-sm p-4 leading-5 rounded-1.5 flex items-center justify-center w-full">
            <span>
              {isSelectAll
                ? t('amlBanner.allRowSelected', { rowLength: rows.length })
                : t('amlBanner.title', {
                    rowLength: isAllSelectedOnPage.count,
                  })}
              <FrankieButton
                noStyles
                className="text-primary-800 cursor-pointer"
                onClick={handleSelectAllClick}
              >
                {isSelectAll
                  ? t('amlBanner.clearRow')
                  : t('amlBanner.selectAll', { total: rows.length })}
              </FrankieButton>
            </span>
          </div>
        ) : null}
      </div>

      {rows.length === 0 ? (
        !noFilter && (
          <div className="text-tertiary-grey-700  text-left">
            <IndividualAmlNoRowPlaceholder />
          </div>
        )
      ) : (
        <DataGridPro
          key={`${isComment}`}
          rows={rows}
          apiRef={apiRef}
          onRowSelectionModelChange={id => handleSelectionModelChange(id)}
          rowSelectionModel={selectedRows}
          slots={{
            baseCheckbox: WithProps(TableCheckbox, {
              totalCount: rows.length,
              selectedCount: Object.keys(
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                (apiRef.current?.getSelectedRows &&
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  apiRef.current?.getSelectedRows()) ||
                  {},
              ).length,
              setSelectionModel: setSelectedRows,
            }),
            noRowsOverlay: EmptyOverlay,
            loadingOverlay: EmptyOverlay,
            filterPanel: GridFilterPanel,
            pagination: WithProps(PaginationWithPageSize, {
              totalCount: rows.length,
              count: Math.ceil(rows.length / paginationModel.pageSize),
              page: paginationModel.page + 1,
              pageSize: paginationModel.pageSize,
              onChange: (_val, page) => {
                setPaginationModel(prev => ({ ...prev, page: page - 1 }))
              },
              onChangePageSize: pageSize => {
                setPaginationModel({ pageSize, page: 0 })
              },
            }),
          }}
          sortingMode="client"
          getRowClassName={getRowClassName}
          getCellClassName={() => cellClassName}
          checkboxSelection={canUpdateAlert}
          className="
           [&_.MuiDataGrid-virtualScrollerContent]:border-neutral-30 [&_.MuiDataGrid-footerContainer]:border-none [&_.MuiDataGrid-columnHeadersInner]:bg-tertiary-grey-50 [&_.MuiDataGrid-columnHeaderTitle]:text-xs [&_.MuiDataGrid-columnHeaderTitle]:!whitespace-break-spaces  [&_.MuiDataGrid-columnHeaderTitle]:font-medium [&_.MuiDataGrid-columnHeadersInner]:text-tertiary-grey-500  [&_.MuiDataGrid-columnHeader]:!outline-none"
          columns={columns.map(column => ({
            disableReorder: true,
            disableColumnMenu: true,
            hideSortIcons: true,
            resizable: false,
            sortable: false,
            headerClassName: headerClassName(
              column === columns[columns.length - 1],
            ),
            cellClassName,
            ...column,
          }))}
          pageSizeOptions={[5, 10, 20]}
          paginationMode="client"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowHeight={68}
          columnHeaderHeight={34}
          columnBuffer={0}
          disableRowSelectionOnClick
          onRowClick={onRowClick}
          disableVirtualization={dataVirtualization}
          disableColumnSelector
          // disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSortModelChange={model => setSortModel(model)}
          sortModel={sortModel as GridSortItem[]}
          checkboxSelectionVisibleOnly
          pagination
          hideFooterSelectedRowCount
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              bgcolor: 'transparent',
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              bgcolor: 'transparent',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
            },
            '.MuiDataGrid-columnHeaders': {
              minHeight: 'unset !important',
              maxHeight: 'unset !important',
              lineHeight: 'unset !important',
              borderRadius: '0 !important',
            },
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              height: '100%',
            },
            '.MuiDataGrid-cell': {
              minHeight: 'unset !important',
              maxHeight: 'unset !important',
              lineHeight: 'unset !important',
              padding: '0 !important',
            },
            '& .MuiDataGrid-main': {
              flexGrow: 0,
            },
          }}
        />
      )}
    </div>
  )
}
