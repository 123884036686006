import React from 'react'

import { Typography } from '@mui/material'
import type {
  GridRenderCellParams,
  GridRowModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'

import { FrankieTooltip } from 'frankify/src'

export function ApplicantRelatedOrganisationsNameCell(
  props: GridRenderCellParams<GridValidRowModel, string>,
) {
  const name = props.value ?? ''
  const isTruncated = name.length > 70

  return isTruncated ? (
    <FrankieTooltip body={<Typography>{name}</Typography>}>
      <Typography
        className="truncate font-semibold text-tertiary-grey-700 w-full"
        noWrap
      >
        {name}
      </Typography>
    </FrankieTooltip>
  ) : (
    <Typography className="font-semibold text-tertiary-grey-700" noWrap>
      {name}
    </Typography>
  )
}

export function ApplicantRelatedOrganisationsRelationCell(
  props: GridRenderCellParams<GridRowModel, string[]>,
) {
  const relations = props.value ?? []
  return relations.map(relation => (
    <p
      key={`${props.id}-${relation.replace(/\s/g, '').toLowerCase()}`}
      className="MuiDataGrid-cellContent truncate w-full"
    >
      {relation}
    </p>
  ))
}
