import React, { useCallback, useState } from 'react'

import {
  Controller,
  ControllerRenderProps,
  ControllerProps,
  FieldValues,
  Path,
  FieldError,
  ControllerFieldState,
} from 'react-hook-form'

import {
  FrankieIcon,
  FrankieSelectField,
  FrankieTextField,
  IFrankieTextFieldProps,
} from 'frankify/src'

import { useI18n } from 'shared/i18n'
import { parsePhoneNumber } from 'shared/phone-number'
import { VALIDATION_LOCALE, validationEn } from 'shared/validation'

type Props<TFormValues extends FieldValues> = Omit<
  IFrankieTextFieldProps,
  keyof ControllerRenderProps
> &
  Omit<ControllerProps<TFormValues>, 'render'> & {
    showErrorText?: boolean
    countryName: Path<TFormValues>
    countryList: { label: string; value: string; alpha2code: string }[]
    validatePhoneNumber?: boolean
    trim?: boolean
  }

export function PhoneFormField<TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  countryName,
  countryList,
  rules: extraRules,
  shouldUnregister,
  supportingText,
  showErrorText = false,
  validatePhoneNumber = false,
  trim = false,
  ...textFieldProps
}: Props<TFormValues>) {
  const [error, setError] = useState<FieldError | undefined>()

  const t = useI18n('common', { keys: { [VALIDATION_LOCALE]: validationEn } })

  const phoneRender = useCallback(
    ({
      field,
      fieldState: { error },
    }: {
      field: ControllerRenderProps<TFormValues, Path<TFormValues>>
      fieldState: ControllerFieldState
    }) => {
      setError(error)
      return (
        <FrankieTextField
          {...textFieldProps}
          {...field}
          error={!!error}
          onBlur={e => {
            if (trim) {
              field.onChange(e.target.value.trim())
            }
            field.onBlur()
          }}
          onChange={e => {
            if (e.target.value.match(/^[0-9]*$/)) {
              field.onChange(e)
            } else {
              e.preventDefault()
            }
          }}
          // label already rendered in FrankieSelectField
          label={undefined}
          className="rounded-s-0"
          inputClassName="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50  rounded-l-none border"
        />
      )
    },
    [textFieldProps],
  )

  const isPhoneNumberValid = (phoneNumber: string, phoneCountry: string) => {
    if (!validatePhoneNumber) return true

    const countryOption = countryList.find(i => i.value === phoneCountry)

    try {
      const parsedPhoneNumber = parsePhoneNumber(
        `${countryOption?.label as string}-${phoneNumber}`,
      )

      if (countryOption?.alpha2code === parsedPhoneNumber.country) {
        return true
      }
    } catch (e) {
      return t('validation.validation.phoneNumber')
    }
    return t('validation.validation.phoneNumber')
  }

  const getRules = (phoneCountry: string) => ({
    ...extraRules,
    validate: {
      isPhoneNumberValid: (value: string) =>
        isPhoneNumberValid(value, phoneCountry),
      ...extraRules?.validate,
    },
  })

  return (
    <div>
      <Controller
        render={({ field }) => (
          <div className="flex items-end">
            <FrankieSelectField
              // only label is required

              label={textFieldProps.label}
              // for current implementation, we are not allowing user to change the country code
              disabled
              {...field}
              error={!!error}
              options={countryList}
              className="!w-[130px] rounded-e-0 [&_.frankie-select-toggle]:!hidden"
              inputClassName=" block p-2.5 w-full z-20 !pr-0 text-sm text-gray-900 bg-gray-50 border-e-0 rounded-r-none border"
            />
            <Controller
              render={phoneRender}
              control={control}
              name={name}
              defaultValue={defaultValue}
              rules={getRules(field.value)}
              shouldUnregister={shouldUnregister}
            />
          </div>
        )}
        control={control}
        name={countryName}
        defaultValue={defaultValue}
        shouldUnregister={shouldUnregister}
      />

      {showErrorText && error && (
        <div className="grow text-mono-70 pt-2 text-sm">
          <div className="flex flex-initial items-center text-tertiary-red-700">
            <div className="p-0.5 mr-2">
              <FrankieIcon name="mdiAlertCircleOutline" size="sm" />
            </div>
            <div>{error.message}</div>
          </div>
        </div>
      )}
      {!(showErrorText && error) && supportingText && (
        <div className="grow text-mono-70 pt-2 text-sm">
          <div className="flex flex-initial items-center">
            <div className="p-0.5 mr-2">
              <FrankieIcon name="mdiAlertCircleOutline" size="sm" />
            </div>
            <div>{supportingText}</div>
          </div>
        </div>
      )}
    </div>
  )
}
