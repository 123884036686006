import React from 'react'

import { CommentHOC, CommentsTypes } from 'entities/comments'

import { IndividualAmlMatchSummary } from './individual-aml-entity-summary/individual-aml-entity-summary'
import { IndividualAmlMatchData } from './individual-aml-match-data/individual-aml-match-data'
import { amlResultQa } from '../qa/individual-aml-result.qa'

type Props = {
  entityId: string
  processResultId?: string
}
export function IndividualAmlResult({ entityId, processResultId }: Props) {
  return (
    <CommentHOC
      entityId={entityId}
      commentType={CommentsTypes.AMLRESULT}
      processId={processResultId}
    >
      <div className="flex w-full gap-6" data-qa={amlResultQa.container}>
        <IndividualAmlMatchSummary
          entityId={entityId}
          processResultId={processResultId}
        />
        <IndividualAmlMatchData
          entityId={entityId}
          processResultId={processResultId}
        />
      </div>
    </CommentHOC>
  )
}
