import React, { Suspense, useCallback, useEffect, useState } from 'react'

import { Outlet } from 'react-router-dom'

import { FrankieLoader, upBreakpoint } from 'frankify/src'

import {
  REACT_GLOBAL_SEARCH_FULLSCREEN_EVENT,
  REACT_GLOBAL_SEARCH_FULLSCREEN_EVENT_DETAIL,
} from 'app/portal-vue-compatibility'

import { ApplicantsSearchParamsTypes } from 'features/applicants-filter'

import { ApplicantId } from 'entities/applicant'
import { PermissionTypes } from 'entities/role'
import {
  TPath,
  TPathParams,
  useGetPathValue,
  useHasFeatureFlag,
  useHasPermission,
  useSessionQuery,
} from 'entities/session'

import { useAppcuesUrlListener } from 'shared/appcues'
import { useFrankieHelp } from 'shared/scripts'

import { PortalHeader } from '../portal-header/portal-header'
import { PortalNav } from '../portal-nav/portal-nav'

type Props = {
  applicantsPath: string
  auxiliaryApplicantPath: string
  needsReviewPath: string
  analyticsPath: TPath
  blocklistPath: TPath
  newProfilePath: TPath
  settingsPath: string
  loginPath: string
  searchResultsPath: string
  applicantPath: TPath
  searchResultsNotificationPath: string
  getApplicantPathParams: (applicantId: ApplicantId) => TPathParams
}
export function LayoutPortal({
  blocklistPath,
  analyticsPath,
  newProfilePath,
  applicantsPath,
  auxiliaryApplicantPath,
  settingsPath,
  loginPath,
  needsReviewPath,
  searchResultsPath,
  applicantPath,
  searchResultsNotificationPath,
  getApplicantPathParams,
}: Props) {
  const {
    canSeeApplicantsPage,
    canSeeApplicantsReviewPage,
    canSeeDashboardPage,
    canSeeNewApplicantCta,
  } = useHasPermission({
    canSeeApplicantsPage: [
      PermissionTypes.ApplicantsPage,
      PermissionTypes.MonitoringIndex,
      PermissionTypes.MonitoringCaseManagementData,
    ],
    canSeeApplicantsReviewPage: [
      PermissionTypes.ApplicantsReviewPage,
      PermissionTypes.OnboardingIndex,
      PermissionTypes.OnboardingIndexData,
    ],
    canSeeDashboardPage: PermissionTypes.ReportingDashboard,
    canSeeNewApplicantCta: PermissionTypes.ApplicantNew,
  })
  const { showVerticalNavBar, isFrankie2 } = useHasFeatureFlag({
    showVerticalNavBar: 'verticalNavBar',
    isFrankie2: 'frankie2customer',
  })

  const [globalSearchOpen, setGlobalSearchOpen] = useState<boolean>(false)

  const { data: session } = useSessionQuery()

  const { getPathValue, generatePathValue } = useGetPathValue()

  const getApplicantPath = useCallback(
    (
      applicantId: ApplicantId,
      search?: { matchStatusFilter?: string; assigneeFilter?: string },
    ) => {
      const isBatched = applicantId.includes(',')
      if (isBatched) {
        const searchParams = new URLSearchParams()
        if (search?.matchStatusFilter) {
          searchParams.set(
            ApplicantsSearchParamsTypes.MatchStatusFilter,
            search.matchStatusFilter,
          )
        }
        if (search?.assigneeFilter) {
          searchParams.set(
            ApplicantsSearchParamsTypes.AssigneeFilter,
            search.assigneeFilter,
          )
        }
        return `${searchResultsNotificationPath}?${searchParams.toString()}`
      }

      return generatePathValue(
        applicantPath,
        getApplicantPathParams(applicantId),
      )
    },
    [
      generatePathValue,
      applicantPath,
      getApplicantPathParams,
      searchResultsNotificationPath,
    ],
  )

  useFrankieHelp({ enable: !!session?.user })

  useAppcuesUrlListener()

  useEffect(() => {
    function resizeListener() {
      let fullscreen = false
      if (!upBreakpoint('tablet') && globalSearchOpen) {
        fullscreen = true
      }
      const event = new CustomEvent(REACT_GLOBAL_SEARCH_FULLSCREEN_EVENT, {
        detail: {
          [REACT_GLOBAL_SEARCH_FULLSCREEN_EVENT_DETAIL]: fullscreen,
        },
      })
      window.dispatchEvent(event)
    }

    window.addEventListener('resize', resizeListener, {
      passive: true,
    })
    resizeListener()

    return () => window.removeEventListener('resize', resizeListener)
  }, [globalSearchOpen])

  if (showVerticalNavBar) {
    return (
      <div className="flex flex-row">
        <div
          className={`bg-tertiary-grey-50 min-h-screen ${
            globalSearchOpen ? 'hidden tablet:block' : ''
          }`}
        >
          <PortalNav
            canSeeApplicantsPage={canSeeApplicantsPage}
            canSeeDashboardPage={canSeeDashboardPage && !isFrankie2}
            canSeeApplicantsReviewPage={canSeeApplicantsReviewPage}
            canSeeBlocklistPage={!isFrankie2}
            analyticsPath={getPathValue(analyticsPath)}
            blocklistPath={getPathValue(blocklistPath)}
            applicantsPath={applicantsPath}
            auxiliaryApplicantPath={auxiliaryApplicantPath}
            needsReviewPath={needsReviewPath}
          />
        </div>
        <div className="min-w-0 bg-mono-white shrink basis-full">
          <PortalHeader
            searchResultsPath={searchResultsPath}
            globalSearchOpen={globalSearchOpen}
            setGlobalSearchOpen={setGlobalSearchOpen}
            loginPath={loginPath}
            newProfilePath={getPathValue(newProfilePath)}
            settingsPath={settingsPath}
            canSeeNewApplicantCta={canSeeNewApplicantCta}
            getApplicantPath={getApplicantPath}
          />
          <Suspense fallback={<FrankieLoader loading />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Outlet />
    </div>
  )
}
