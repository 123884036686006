export const individualProfileEn = {
  frankieId: 'Frankie ID',
  title: 'Create New Profile',
  ok: 'OK',
  creditHeaderTitle: 'Credit Header - No Match',

  hasBeenUpdated: '{{fullName}} profile has been updated.',
  hasBeenCreated: 'New Profile {{fullName}} has been created.',
  profileCheckError:
    'Checks could not be completed at this time. Please contact support.',
  plusIconTestId: 'create-icon-test-id',
  individual: 'Individual',
  business: 'Business',
  personalInfo: 'Personal Info',
  residentialAddress: 'Residential Address',
  currentAddress: 'Current Residential Address',
  recipe: 'Recipe',
  addAddressManually: 'Add address manually',
  suggestions: 'Suggestions',
  noSearchResults: 'No results found. Click here to add address manually.',
  previousResidentialAddress: 'Previous Residential Address',
  mailingAddress: 'Mailing Address',
  removeAddress: 'Remove Address',
  saveVerify: 'Save & verify',
  saveChanges: 'Save changes',
  saveApprove: 'Save & Approve',
  optional: '(optional)',
  recipeToolTip:
    'A recipe is a set of rules and checks that will be run on this individual.',

  profileForm: {
    customerId: 'Customer ID',
    name: 'Name',
    customerIdPlaceHolder: 'Customer ID',
    optional: '(optional)',
    firstName: 'First Name',
    firstNamePlaceholder: 'Given Name',
    lastName: 'Last Name',
    lastNamePlaceHolder: 'Family Name',
    middleName: 'Middle Name',
    middleNamePlaceholder: 'Middle Name',
    dob: 'Date of Birth',
    dobPlaceholder: 'DD-MM-YYYY',
    phoneNumber: 'Phone Number',
    phoneNumberPlaceholder: 'Mobile Number',
    email: 'Email Address',
    emailPlaceholder: 'Email Address',
    country: 'Country',
    searchAddress: 'Search for an address',
    errors: {
      unacceptableText: 'Unacceptable text format.',
    },
  },

  addressFrom: {
    addAddressManually: 'Add address manually',
    addAnotherAddress: 'Add another address',
    optional: '(optional)', // Remove
    propertyName: 'Property Name',
    propertyNamePlaceholder: 'Property Name',
    unitNumber: 'Unit Number',
    unitNumberPlaceholder: 'Unit Number',
    streetNumber: 'Street Number',
    streetNumberPlaceholder: 'Street NUmber',
    streetName: 'Street Name',
    streetNamePlaceholder: 'Street Name',
    streetType: 'Street Type',
    streetTypePlaceholder: 'Street Type',
    suburb: 'Suburb',
    suburbPlaceholder: 'Suburb',
    townCity: 'Town/City',
    town: 'Town',
    city: 'City',
    suburbLocality: 'Suburb/Locality',
    district: 'District',
    state: 'State',
    provinceTerritory: 'Province/Territory',
    stateTerritory: 'State or territory',
    postalCode: 'Postal Code',
    zipCode: 'ZipCode',
    stateOrTerritory: 'State or Territory',
    stateOrTerritoryPlaceholder: 'State or Territory',
    postalCodePlaceholder: 'Postal Code',
    detailAddressHeading: {
      empty: 'Add address manually',
      filled: 'Check the address has been input into the appropriate fields',
    },
    sameAsCurrent: 'Same as current residential address',
  },

  manualKyc: {
    mKycRequirement:
      'Please provide one of the following combinations of documents to fulfil <0>manual KYC requirements:</0>',
    docTypeReq1: 'At least <0>1 Primary Photographic</0> ID document',
    docTypeReq2: 'At least <0>2 Primary Non-photographic</0> ID documents',
    docTypeReq3:
      '<0>1 Primary Non-photographic</0> ID Document & <0>1 Secondary</0> ID Document',
    category: {
      primary: 'Primary Photographic',
      nonPrimary: 'Primary Non Photographic',
      secondary: 'Secondary',
    },
  },

  documentForm: {
    title: 'ID Documents',
    heading: 'ID Document',
    nationIdNumber: 'National ID Number',
    remove: 'Remove ID',
    deleteDocument: 'Delete Document',
    delete: 'Delete',
    cancel: 'Cancel',
    confirmRemove: 'Are you sure you want to delete this {{idType}} document?',
    mKyc: 'mKYC',
    eKyc: 'eKYC',

    plateNumber: 'Licence Plate',
    idType: 'ID Type',
    idTypePlaceholder: 'Select ID Type',
    country: 'Country',
    addAnother: 'Add another document',
    state: 'State',
    statePlaceholder: 'State',
    passportNumber: 'Passport Number',
    docCardNumber: 'Document/Card number',
    licenseNumber: 'Licence Number',
    licenseNumberPlaceholder: 'Licence Number',
    number: 'Number',
    numberPlaceholder: '10 digits',
    marriageDate: 'Marriage Date',
    stateOfRegistration: 'State or territory of registration',
    stateOfRegistrationPlaceholder: 'State or territory',
    registrationNumber: 'Registration Number',
    registrationDate: 'Registration Date',
    registrationDatePlaceholder: 'YYYY',
    registeredName: 'Registered Name',
    firstGiveName: 'First Given Name',
    otherGivenName: 'Other Given Name ',
    familyName: 'Family Name',
    description: 'Description',
    positionOnCard: 'Position On Card',
    positionOnCardPlaceholder: '1 digit',
    cardColor: 'Card Colour',
    dateOfExpiry: 'Date Of Expiry',
    dateOfExpiryPlaceholder: 'MM-YYYY',
    displayMiddleName: 'How is middle name displayed on the card?',
    nameAsDisplay: 'Name as displayed on card',
    fullMiddleName: 'Full Middle name',
    noMiddleName: 'No Middle name',
    middleNameInitial: 'Middle Initial Only',
    stockNumber: 'Stock Number',
    stockNumberPlaceholder: 'Number',
    acquisitionDate: 'Date Of Acquisition',
    datePlaceholder: 'DD-MM-YYYY',
    primaryCountry: 'Primary Country',
    countryOfBirth: 'Country Of Birth',
    secondaryCountry: 'Secondary Country',
    partnerOne: 'Partner One (Brides) Name',
    partnerTwo: 'Partner Two (Grooms) Name',
    idNumber: 'ID Number',
    idSubType: 'ID SubType',
    documentCategory: 'Document Category',
    documentNumber: 'Document/Card Number',
    documentNumberPlaceholder: 'Document/Card Number',
    registrationDistrict: 'Registration District',
    certificateNumber: 'Certificate Number',
    dateOfPrint: 'Date of Print',
    placeOfBirth: 'Place of Birth',
    fileNumber: 'File number',
    homeCountryFullName: 'Full Name as Shown on ID',
    licenceVersion: 'Licence version',
    bankCardNumber: 'Bank Card Number',
    paternalSurname: 'Paternal Name',
    maternalSurname: 'Maternal Name',
    laserCode: 'Laser Code',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
  },

  idType: {
    passport: 'Passport',
    driverLicense: 'Driver Licence',
    visa: 'Visa',
    immigration: 'Immigration',
    birthCertificate: 'Birth Certificate',
    medicareCard: 'Medicare Card',
    citizenshipCertificate: 'Citizenship Certificate',
    changeOfNameCert: 'Change Of Name Certificate',
    marriageCertificate: 'Marriage Certificate',
    motorVehicleRegistration: 'Motor Vehicle Registration',
    nationalID: 'National ID',
    taxId: 'Tax ID',
    voterId: 'Voter ID',
    panCard: 'Pan Card',
    other: 'Other',
    bankStatement: 'Bank Card',
    ssn: 'SSN',
    hongKongId: 'Hong Kong ID',
  },

  additionalText: {
    dni: '(DNI)',
    cpf: '(CPF)',
    curp: '(CURP/RFC)',
    smartId: '(Smart ID)',
    hongKongId: 'Hong Kong ID',
    epic: '/EPIC',
    panCard: 'Pan Card',
    ssn: 'SSN',
    voterId: 'Voter ID',
  },

  consentForm: {
    title: 'Consent',
    eKyc: 'You have obtained consent from your customer to the collection, use and disclosure of their personal information in accordance with your privacy policy, and for the purposes of verifying their identity, they consent to: (a) the verification of their personal information with a credit bureau header files (for verification only); (b) against records held by official document issuers or official record holders via third party systems; and c) your verification agent(s) acting as a nominated intermediary in accordance with Australian Privacy Principles. They consent to the use by third parties of the results of any verification checks on their identity for the purposes of monitoring and improving the verification services.',
    mKyc: 'You attest that you have sighted the customer identity documents and you have uploaded true copies of the sighted documents for manual KYC verification.',
    general: {
      mKyc: 'You have obtained consent from your customer to the collection, use and disclosure of their personal information being checked in connection with a request to verify their identity.',
      eKyc: 'You attest that you have sighted the customer identity documents and you have uploaded true copies of the sighted documents for manual KYC ',
      title: ' General Consent.',
    },
    docs: {
      mKyc: 'You have obtained consent from your customer to the collection, use and disclosure of their personal information being checked with the document issuer or official record holder via third-party systems; in connection with a request to verify their identity.',
      title: ' Government ID.',
    },
    creditHeader: {
      mKyc: 'You have obtained consent from your customer to the collection, use and disclosure of their personal information being disclosed to a credit reporting agency in connection with a request to verify their identity.',
      title: ' Credit Header.',
    },
    comment: 'Comments',
    addYourComment: 'Add your comments here',
  },
  forceCheck: 'Force all checks to be re-run regardless of previous results',
  manualKycError:
    'The minimum document requirements for manual KYC have not been met.',
  missingDocsError:
    'You must submit at least one document for this Recipe check type.',
}
