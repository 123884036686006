import React, { Suspense, useEffect, useMemo, useRef } from 'react'

import { Outlet, generatePath, useNavigate, useParams } from 'react-router-dom'

import { FrankieBadge, FrankieButton, FrankieLoader } from 'frankify/src'

import { applicantNavQa, routingQa } from 'app/routing/qa/routing.qa'

import { useSendLinkBannerState } from 'features/applicant-workflow-events'
import { IndividualSendVerificationLink } from 'features/individual-send-verification-link'

import {
  ApplicantRouteConfig,
  ApplicantTabTypes,
} from 'entities/applicant/model/applicant.model'
import { CommentsTypes, CommentToolTip } from 'entities/comments'
import {
  ArchiveEntity,
  AssignEntity,
  useEntityDataQuery,
  ServiceProfileState,
  useFrankie2R2Customer,
} from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useHandleBack } from 'entities/routing'
import { TPath, useGetPathValue, useHasPermission } from 'entities/session'

import { Menu } from 'shared/components'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import { modifyRecord } from 'shared/typescript'

import { ROUTING_KEY, routingEn } from '../../../locale/routing.en'
import { NavTabOption, NavTabs } from '../../nav-tabs/nav-tabs'

type NavData = {
  label: string
  value: ApplicantTabTypes
  testId: string
  commentType: CommentsTypes
}

type Props = ApplicantRouteConfig<TPath>

enum MenuOptionTypes {
  EDIT = 'edit',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
}

export function LayoutApplicantProfileF2({
  applicantIdParamKey,
  paths,
}: Props) {
  const t = useI18n(ROUTING_KEY, { keys: routingEn })
  const { getPathValue, generatePathValue } = useGetPathValue()
  const params = useParams()
  const ref = useRef<HTMLDivElement>(null)
  const { auditReportPermission, editIndividualPermission } = useHasPermission({
    auditReportPermission: PermissionTypes.AuditReportView,
    editIndividualPermission: PermissionTypes.ManualIdvUpdateAll,
  })
  const navigate = useNavigate()

  const { handleBack } = useHandleBack()

  const [createOverlay] = useOverlay()
  /**
   * Final applicant paths using getPathValue
   */
  const applicantPaths = useMemo(
    () => modifyRecord(paths, getPathValue),
    [getPathValue, paths],
  )

  const navData = useMemo<NavData[]>(
    () =>
      [
        {
          label: t('applicantF2.nav.workflowEvents'),
          value: 'workflowEvents',
          commentType: CommentsTypes.WORKFLOW,
          testId: applicantNavQa.workflowEvents,
        },
        {
          label: t('applicantF2.nav.personalInfoKyc'),
          value: 'profileInsight',
          commentType: CommentsTypes.PROFILE,

          testId: applicantNavQa.profileInsight,
        },
        {
          label: t('applicantF2.nav.auditReport'),
          value: 'auditReport',
          hidden: !auditReportPermission,
          commentType: CommentsTypes.AUDIT,
          testId: applicantNavQa.auditReport,
        },
      ].filter(navItem => !navItem.hidden) as NavData[],

    [t, auditReportPermission],
  )

  const applicantId = params[applicantIdParamKey]

  const { data } = useEntityDataQuery(applicantId)

  const { canSendLink, hasTwilioEnabled } = useSendLinkBannerState(applicantId!)

  const navOptions = useMemo<(NavTabOption & { comment: CommentsTypes })[]>(
    () =>
      navData.map(item => ({
        label: item.label,
        path: generatePath(applicantPaths[item.value], {
          [applicantIdParamKey]: applicantId,
        }),
        comment: item.commentType,
        testId: item.testId,
      })),
    [applicantId, applicantIdParamKey, applicantPaths, navData],
  )

  const handleMenuSelect = (value: string) => {
    if (MenuOptionTypes.EDIT === value) {
      navigate(
        generatePathValue(applicantPaths.editEntity, {
          [applicantIdParamKey]: applicantId,
        }),
      )
    }
    if (
      MenuOptionTypes.ARCHIVE === value ||
      MenuOptionTypes.UNARCHIVE === value
    ) {
      createOverlay(
        <ArchiveEntity
          onSuccess={() =>
            navigate(
              generatePathValue(applicantPaths.profileInsight, {
                [applicantIdParamKey]: applicantId,
              }),
            )
          }
          entityId={applicantId}
        />,
        {
          closeButtonClassName: '!top-5 !right-5',
          className: '!p-5',
        },
      )
    }
  }

  const menuOptions = useMemo(
    () =>
      [
        {
          label: t('applicantF2.dropdown.edit'),
          value: MenuOptionTypes.EDIT,
          testId: routingQa.applicantMenuEditCta,
          hidden: !editIndividualPermission,
        },
        {
          label: t('applicantF2.dropdown.archive'),
          value: MenuOptionTypes.ARCHIVE,
          testId: routingQa.applicantMenuArchiveCta,
          hidden:
            !editIndividualPermission ||
            !data ||
            (data.serviceProfiles?.[0]?.state &&
              [
                ServiceProfileState.ARCHIVED,
                ServiceProfileState.INACTIVE,
                ServiceProfileState.DELETED,
              ].includes(data.serviceProfiles[0].state)),
        },
        {
          label: t('applicantF2.dropdown.unarchive'),
          value: MenuOptionTypes.UNARCHIVE,
          testId: routingQa.applicantMenuUnarchiveCta,
          hidden:
            !editIndividualPermission ||
            !data ||
            data.serviceProfiles?.at(0)?.state !== ServiceProfileState.ARCHIVED,
        },
      ].filter(option => !option.hidden),
    [editIndividualPermission, t, data?.serviceProfiles],
  )

  const customerId = useMemo(
    () =>
      data?.individual?.externalReferences?.find(
        i => i.name === 'CUSTOMER-REFERENCE',
      )?.value,
    [data?.individual?.externalReferences],
  )

  const isArchived =
    data?.serviceProfiles?.at(0)?.state === ServiceProfileState.ARCHIVED

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.EntityViewShowIndividual)
  }, [])

  const isFrankie2R2 = useFrankie2R2Customer()

  const currentCommentType = navOptions.find(
    item => item.path === window.location.pathname,
  )?.comment

  return (
    <div className="[&>*]:px-8 scroll-smooth">
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          <span className="flex justify-start items-center text-tertiary-grey-500">
            <FrankieButton
              noStyles
              startIcon={{
                name: 'mdiArrowLeft',
                size: 'xs',
                className: 'text-tertiary-grey-500',
              }}
              onClick={() => handleBack({ state: { entityId: applicantId } })}
              className="inline-flex gap-1"
            >
              <span className="text-xs">Back</span>
            </FrankieButton>
          </span>
          <div className="flex flex-col gap-1">
            <h2
              data-hj-suppress
              className="text-xl font-bold text-tertiary-grey-800 leading-7 flex gap-3 items-center"
            >
              {data?.individual?.name?.displayName}

              {isArchived && (
                <FrankieBadge
                  theme="grey"
                  className="capitalize min-w-[68px] h-[21px]"
                  text={data.serviceProfiles?.[0].state?.toLowerCase()}
                />
              )}
            </h2>
            <div className="flex gap-2 text-tertiary-grey-500 text-xs leading-4">
              {customerId && (
                <div>
                  <span className="font-medium ">
                    {t('applicant.customerRef')}
                  </span>{' '}
                  <span data-hj-suppress>{customerId}</span>
                  {' | '}
                </div>
              )}

              {data?.individual?.entityId && (
                <div>
                  <span className="font-medium">Entity ID</span>{' '}
                  <span>{data.individual.entityId}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex shrink items-center gap-3 mt-6 align-start">
          <AssignEntity entityId={applicantId} />

          {isFrankie2R2 && currentCommentType && (
            <CommentToolTip
              className="!self-center !pt-1 !z-0"
              entityId={applicantId!}
              commentType={currentCommentType}
            />
          )}

          {canSendLink && hasTwilioEnabled && (
            <IndividualSendVerificationLink entityId={applicantId} />
          )}

          {/* don't show menu when there are no options to show */}
          {menuOptions.length > 0 && (
            <Menu
              testId={{ button: routingQa.applicantMenuCta }}
              options={menuOptions}
              onSelect={handleMenuSelect}
            />
          )}
        </div>
      </div>
      <div>
        <NavTabs
          options={navOptions}
          className="border-b border-tertiary-grey-200  "
          arrowButtonClassName="hidden"
          navItemClassName="first:ml-0"
          replace
        />
      </div>
      <div
        ref={ref}
        className="scroll-smooth h-[calc(100vh-186px)] relative flex flex-col pb-24 pt-8 scrollbar overflow-y-auto z-0 overflow-x-hidden"
      >
        <Suspense fallback={<FrankieLoader loading />}>
          <Outlet
            context={{ paths: applicantPaths, applicantIdParamKey, ref }}
          />
        </Suspense>
      </div>
    </div>
  )
}
