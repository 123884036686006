import React from 'react'

import { useValidationSchema } from 'features/individual-profile-f2/model/validationSchema'

import { AustralianStateCodeTypes, stateList } from 'entities/country'
import { DocumentType } from 'entities/entity'

import { SelectFormField, TextFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../../../individual-profile-f2.key'
import { individualProfileF2En } from '../../../../locale/individual-profile-f2.en'
import { getDriverLicenceQa } from '../../../../qa/individual-profile-f2.qa'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

export function DriverLicenseForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const { control, watch } = form

  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })

  const { documents } = useValidationSchema()
  const statesOptions = stateList.AUS

  const state = watch(`documents.${idx}.subdivision`)

  const getRequiredRule = (label: Parameters<typeof t>['0']) => ({
    required: t('errors.requiredEntity', {
      label: t(label).toLowerCase(),
    }),
  })

  const driverLicenceF2Qa = getDriverLicenceQa(idx)

  const { primaryIdentifier, secondaryIdentifier } = state
    ? documents[DocumentType.DRIVERS_LICENSE].AUS[
        state as AustralianStateCodeTypes
      ]
    : { primaryIdentifier: {}, secondaryIdentifier: {} }

  return (
    <div className={wrapperClasses} data-qa={driverLicenceF2Qa.wrapper}>
      <SelectFormField
        options={statesOptions}
        className="basis-[24%]"
        label={t('documentForm.state')}
        control={control}
        rules={{
          required: t('errors.select', {
            label: t('addressForm.stateOrTerritory'),
          }),
        }}
        name={`documents.${idx}.subdivision`}
        showErrorText
        testId={{ input: driverLicenceF2Qa.state }}
      />

      <TextFormField
        trim
        key={`${state ?? ''}-primaryIdentifier`}
        className="basis-[35%]"
        label={t('documentForm.licenseNumber')}
        control={control}
        name={`documents.${idx}.primaryIdentifier`}
        rules={{
          ...getRequiredRule('documentForm.licenseNumberPlaceholder'),
          ...primaryIdentifier,
        }}
        showErrorText
        shouldUnregister
        testId={{ input: driverLicenceF2Qa.licenseNumber }}
      />

      <TextFormField
        trim
        key={`${state ?? ''}-secondaryIdentifier`}
        className="basis-[35%]"
        label={t('documentForm.cardNumber')}
        control={control}
        name={`documents.${idx}.secondaryIdentifier`}
        rules={{
          ...getRequiredRule('documentForm.cardNumberPlaceholder'),
          ...secondaryIdentifier,
        }}
        showErrorText
        shouldUnregister
        testId={{ input: driverLicenceF2Qa.cardNumber }}
      />
    </div>
  )
}
