import React, { useState } from 'react'

import { FrankieAlert, FrankieButton } from 'frankify/src'

import { trustDeedsDocQa } from 'features/applicant-supporting-documents/qa/applicant-support-documents.qa'

import { useI18n } from 'shared/i18n'
import { capitalizeStringBySeparator } from 'shared/string'

import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../locale/applicant-supporting-documents.en'
import { TrustTypes } from '../../model/applicant-supporting-documents.model'
import { TrustAlertTypes } from '../../model/applicant-supporting-trust-deed.model'

type Props = {
  status: TrustAlertTypes | null
  onChangeTrustType: (type: TrustTypes) => void
  analysisIsLoading: boolean
  fileName?: string
  provided?: TrustTypes
  detected?: TrustTypes
  showAlert?: boolean
}

export function TrustAnalyserAlert({
  status,
  fileName,
  analysisIsLoading,
  provided,
  detected,
  onChangeTrustType,
  showAlert,
}: Props) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const [dismissAlert, setDismissAlert] = useState<boolean>(true)

  if (!showAlert) return null

  if (status === TrustAlertTypes.FAILED) {
    return (
      <FrankieAlert
        type="error"
        dismissible
        className="break-words mt-8 mx-8"
        closeIconSize="md"
        closeIconClassName="text-tertiary-grey-400"
        title={
          <div className="text-base font-semibold text-tertiary-grey-800">
            {t('trustDeedAnalysisValidation.documentNotDetected.title', {
              fileName,
            })}
          </div>
        }
        testId={{
          alert: trustDeedsDocQa.notDetectedWarningFrame,
          close: trustDeedsDocQa.notDetectedWarningClose,
        }}
      >
        <p className="text-sm font-normal text-tertiary-grey-700">
          {t('trustDeedAnalysisValidation.documentNotDetected.description')}
        </p>
      </FrankieAlert>
    )
  }

  if (status === TrustAlertTypes.WARNING) {
    return (
      <FrankieAlert
        type="warning"
        className="break-words mt-8 mx-8"
        warningIconClassName="text-tertiary-yellow-300"
        dismissible
        title={
          <div className="text-base font-semibold text-tertiary-grey-800">
            {t('trustDeedAnalysisValidation.documentAutoDetect.title')}
          </div>
        }
        value={dismissAlert}
        closeIconSize="md"
        closeIconClassName="text-tertiary-grey-400"
        testId={{
          alert: trustDeedsDocQa.autoDetectWarningFrame,
          close: trustDeedsDocQa.notDetectedWarningClose,
        }}
      >
        <p className="text-sm font-normal text-tertiary-grey-700">
          {t('trustDeedAnalysisValidation.documentAutoDetect.description')}
        </p>
      </FrankieAlert>
    )
  }

  if (status === TrustAlertTypes.MISMATCH && provided && detected) {
    return (
      <FrankieAlert
        type="warning"
        className="break-words !overflow-hidden mt-8 mx-8"
        warningIconClassName="text-tertiary-yellow-300"
        dismissible
        title={
          <div className="text-base font-semibold text-tertiary-grey-800">
            {t('trustDeedAnalysisValidation.documentMismatch.title')}
          </div>
        }
        value={dismissAlert}
        closeIconSize="md"
        closeIconClassName="text-tertiary-grey-400"
        testId={{
          alert: trustDeedsDocQa.mismatchWarningFrame,
          close: trustDeedsDocQa.notDetectedWarningClose,
        }}
      >
        <p className="text-sm font-normal text-tertiary-grey-700">
          {t('trustDeedAnalysisValidation.documentMismatch.description', {
            provided: capitalizeStringBySeparator(provided),
            detected: capitalizeStringBySeparator(detected),
          })}
        </p>
        <div className="flex mt-2">
          <FrankieButton
            intent="warningPrimary"
            size="md"
            className="truncate !block"
            onClick={() => setDismissAlert(false)}
            testId={{ button: trustDeedsDocQa.confirmTrustTypeButton }}
          >
            {t(
              'trustDeedAnalysisValidation.documentMismatch.confirmAsProvided',
              {
                provided: capitalizeStringBySeparator(provided),
              },
            )}
          </FrankieButton>
          <FrankieButton
            intent="warningSecondary"
            size="md"
            className="border-tertiary-yellow-300 text-tertiary-grey-800 ml-4 truncate !block"
            disabled={analysisIsLoading}
            onClick={() => onChangeTrustType(detected)}
            testId={{ button: trustDeedsDocQa.switchTrustTypeButton }}
          >
            {t(
              'trustDeedAnalysisValidation.documentMismatch.switchToDetected',
              {
                detected: capitalizeStringBySeparator(detected),
              },
            )}
          </FrankieButton>
        </div>
      </FrankieAlert>
    )
  }

  return null
}
