import React, { useMemo, useState } from 'react'

import {
  FrankieIcon,
  FrankieIconName,
  FrankieLoader,
  FrankieTooltip,
} from 'frankify/src'

import { ApplicantId } from 'entities/applicant'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import {
  Nullable,
  PartialRecord,
  SuggestionString,
  aliasKeys,
  isNumber,
} from 'shared/typescript'

import { APPLICANT_BIOMETRICS_OCR_KEY } from '../../applicant-biometrics-ocr.key'
import { applicantBiometricsOcrEn } from '../../locale/applicant-biometrics-ocr.en'
import {
  BiometricDetailType,
  CheckResult,
} from '../../model/applicant-biometrics-ocr.model'
import { applicantBiometricAccordionQa } from '../../qa/applicant-biometric-ocr.qa'
import { useApplicantBiometricsOcrQuery } from '../../state/applicant-biometrics-ocr-query/applicant-biometrics-ocr.query'
import { useApplicantFacialDuplicatesQuery } from '../../state/applicant-facial-duplicates-query/applicant-facial-duplicates.query'
import { ApplicantManualOverrideBiometricsStatus } from '../applicant-manual-override-biometrics-status/applicant-manual-override-biometrics-status'

type FieldViewProps = {
  label: string
  value?: string | number
  inView?: boolean
}

function FieldView({ label, value, inView = false }: FieldViewProps) {
  if (!inView) return null
  return (
    <div className="font-medium">
      <span className="text-xs text-tertiary-grey-400 mr-2 uppercase">
        {label}:
      </span>
      <span className="text-tertiary-grey-900">{value}</span>
    </div>
  )
}

type CheckResultRecord<TValue extends string = string> = PartialRecord<
  SuggestionString<CheckResult>,
  TValue
>

type CheckResultInfoMap = {
  borderColor: string
  textColor: string
  statusIcon?: FrankieIconName
  statusText: string
  bgColor: string
}

const checkResultInfoMap: CheckResultInfoMap = {
  borderColor: 'border-s-tertiary-grey-400',
  textColor: 'text-tertiary-grey-400',
  statusText: '',
  bgColor: 'bg-tertiary-grey-100',
}

type DetailTypeRecord<TValue extends string = string> = Record<
  BiometricDetailType,
  TValue
>

type BiometricAccordionHeaderProps = {
  checksEmpty?: boolean
  reportStatus?: string
  checkDate?: Nullable<string>
  createdDate?: Nullable<string>
  possibleDuplicates?: number
  className?: string
  children: React.ReactNode
  checkResult?: SuggestionString<CheckResult>
  detailType: BiometricDetailType
  applicantId: ApplicantId
  index: number
}

export function BiometricAccordionHeader({
  reportStatus,
  checkDate,
  createdDate,
  checksEmpty = false,
  className = '',
  possibleDuplicates,
  children,
  checkResult,
  detailType,
  applicantId,
  index,
}: BiometricAccordionHeaderProps) {
  const t = useI18n([APPLICANT_BIOMETRICS_OCR_KEY], {
    keys: applicantBiometricsOcrEn,
  })

  const { isFetching: fetchingBiometryData } = useApplicantBiometricsOcrQuery({
    applicantId,
  })

  const { isFetching: fetchingFacialDuplicate } =
    useApplicantFacialDuplicatesQuery({ applicantId })

  const [isExpanded, setIsExpanded] = useState(false)

  const { borderColor, textColor, statusIcon, statusText, bgColor } =
    useMemo(() => {
      const borderColor: CheckResultRecord = {
        ...aliasKeys(['suspected', 'caution'], 'border-s-tertiary-amber-500'),
        ...aliasKeys(['manual_pass', 'clear'], 'border-s-tertiary-green-400'),
        ...aliasKeys(
          ['manual_fail', 'rejected', 'fail'],
          'border-s-tertiary-red-500',
        ),
      }

      const textColor: CheckResultRecord = {
        ...aliasKeys(['suspected', 'caution'], 'text-tertiary-amber-500'),
        ...aliasKeys(['manual_pass', 'clear'], 'text-tertiary-green-400'),
        ...aliasKeys(
          ['manual_fail', 'rejected', 'fail'],
          'text-tertiary-red-500',
        ),
      }

      const bgColor: CheckResultRecord = {
        ...aliasKeys(['suspected', 'caution'], 'bg-tertiary-amber-50'),
        ...aliasKeys(['manual_pass', 'clear'], 'bg-tertiary-green-100'),
        ...aliasKeys(['manual_fail', 'rejected', 'fail'], 'bg-tertiary-red-50'),
      }

      const statusIcon: CheckResultRecord<FrankieIconName> = {
        suspected: 'mdiAlertCircle',
        caution: 'mdiHelpCircleOutline',
        ...aliasKeys(['manual_pass', 'clear'], 'mdiCheckCircle'),
        ...aliasKeys(['manual_fail', 'rejected', 'fail'], 'mdiCloseCircle'),
      }

      const statusText: CheckResultRecord = {
        clear: t('clear'),
        suspected: t('suspected'),
        caution: t('caution'),
        manual_pass: t('manualPass'),
        manual_fail: t('manualFail'),
        rejected: t('rejected'),
        fail: t('fail'),
      }

      if (checkResult) {
        return {
          borderColor:
            borderColor[checkResult] ?? checkResultInfoMap.borderColor,
          textColor: textColor[checkResult] ?? checkResultInfoMap.textColor,
          statusIcon: statusIcon[checkResult] ?? checkResultInfoMap.statusIcon,
          statusText: statusText[checkResult] ?? checkResultInfoMap.statusText,
          bgColor: bgColor[checkResult] ?? checkResultInfoMap.bgColor,
        }
      }

      return checkResultInfoMap
    }, [checkResult, t])

  const { iconName, title } = useMemo(() => {
    const iconName: DetailTypeRecord<FrankieIconName> = {
      biometry: 'mdiFaceRecognition',
      document: 'mdiCardBulletedOutline',
      ocr: 'mdiCardAccountDetails',
      facial: 'mdiAccountMultipleOutline',
    }

    const title: DetailTypeRecord = {
      biometry: t('facialRecognitionReport'),
      document: t('documentReport'),
      ocr: t('ocrReport'),
      facial: t('facialDuplicateReport'),
    }

    return {
      iconName: iconName[detailType],
      title: title[detailType],
    }
  }, [detailType, t])

  const readablePossibleDuplicates = useMemo(
    () => ({
      value: possibleDuplicates === 0 ? t('none') : possibleDuplicates,
      isAvailable: isNumber(possibleDuplicates),
    }),
    [possibleDuplicates, t],
  )

  /**
   * Result Description
   */
  const description = useMemo(() => {
    const description = {
      text: '',
      isAvailable: true,
    }

    if (detailType === 'facial') {
      if (readablePossibleDuplicates.isAvailable) {
        description.text = possibleDuplicates
          ? t('checkResult.facial.multipleDuplicate', {
              count: possibleDuplicates,
            })
          : t('checkResult.facial.duplicate')
      } else description.isAvailable = false
    } else if (checkResult) {
      const descriptionsText: CheckResultRecord = {
        clear: t(`checkResult.${detailType}.clear`),
        suspected: t(`checkResult.${detailType}.suspected`),
        caution: t(`checkResult.${detailType}.caution`),
        manual_pass: t(`checkResult.${detailType}.manualPass`),
        manual_fail: t(`checkResult.${detailType}.manualFail`),
        rejected: t(`checkResult.${detailType}.rejected`),
        fail: '',
      }

      const text = descriptionsText[checkResult]
      description.text = text || ''
      description.isAvailable = !!text
    }

    return description
  }, [
    checkResult,
    detailType,
    possibleDuplicates,
    readablePossibleDuplicates.isAvailable,
    t,
  ])

  const readableCheckDate = useMemo(
    () => ({
      value: checkDate
        ? formatDate(checkDate, DateFormatTypes.FullDateWith24HoursTime)
        : '',
      isAvailable: !!checkDate,
    }),
    [checkDate],
  )

  const readableCreatedDate = useMemo(
    () => ({
      value: createdDate
        ? formatDate(createdDate, DateFormatTypes.FullDateWith24HoursTime)
        : '',
      isAvailable: !!createdDate,
    }),
    [createdDate],
  )

  const showBanner = useMemo(
    () =>
      description.isAvailable &&
      ((checkResult && !['clear', 'manual_pass'].includes(checkResult)) ||
        isExpanded),
    [checkResult, description.isAvailable, isExpanded],
  )

  return (
    <FrankieLoader
      testId={{ loader: applicantBiometricAccordionQa.loader }}
      loading={
        detailType === 'facial' ? fetchingFacialDuplicate : fetchingBiometryData
      }
      label={t('loading.refreshing')}
      className={`py-2 px-4 border border-neutral-30 border-s-4 ${borderColor} rounded-sm ${className}`}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        onClick={() => setIsExpanded(prev => !prev)}
        role="presentation"
        className="flex justify-between py-4 cursor-pointer"
        data-qa={applicantBiometricAccordionQa.toggleBtn}
      >
        <div className="flex shrink-0 basis-4/12">
          <FrankieIcon
            name="mdiChevronRight"
            className={`${
              isExpanded ? 'rotate-90' : ''
            } text-tertiary-grey-300`}
          />
          <FrankieIcon
            name={iconName}
            className="text-tertiary-grey-300 mx-2"
          />
          <div className="text-md text-tertiary-grey-900 font-semibold">
            {title}
          </div>
        </div>

        {checksEmpty ? (
          <div className="text-lg font-bold shrink-0 grow-0 text-tertiary-grey-900 basis-3/6">
            {t('noCheckCompleted')}
          </div>
        ) : (
          <div className="flex justify-center gap-x-2 shrink-0 basis-4/12 [&>*]:shrink-0">
            <FieldView
              inView={readableCheckDate.isAvailable}
              label={t('checkStatus')}
              value={reportStatus}
            />

            <FieldView
              inView={readableCheckDate.isAvailable}
              label={t('lastUpdated')}
              value={readableCheckDate.value}
            />

            <FieldView
              inView={!!readableCreatedDate.isAvailable}
              label={t('createdDate')}
              value={readableCreatedDate.value}
            />

            <FieldView
              inView={readablePossibleDuplicates.isAvailable}
              label={t('possibleDuplicates')}
              value={readablePossibleDuplicates.value}
            />
          </div>
        )}

        <div
          className="flex shrink-0 basis-4/12 justify-end"
          data-qa={applicantBiometricAccordionQa.tooltip}
        >
          <div className={`font-bold text-md ${textColor}`}>{statusText}</div>
          {statusIcon && (
            <FrankieTooltip
              className="min-w-[310px]"
              position="left"
              title={description.text}
            >
              <div>
                <FrankieIcon name={statusIcon} className={`${textColor}`} />
              </div>
            </FrankieTooltip>
          )}
        </div>
      </div>

      <div
        className="ms-8"
        data-qa={applicantBiometricAccordionQa.overrideStatusWrapper}
      >
        {showBanner && (
          <div
            className={`relative flex items-center justify-center h-[56px] ${bgColor} rounded-xs my-2 px-[145px]`}
          >
            <span className={`${textColor} font-bold shrink-0`}>
              {description.text}
            </span>
            <ApplicantManualOverrideBiometricsStatus
              applicantId={applicantId}
              detailType={detailType}
              className="absolute right-2 top-2 flex-nowrap shrink-0"
              index={index}
            />
          </div>
        )}

        {isExpanded && children}
      </div>
    </FrankieLoader>
  )
}
