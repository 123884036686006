export const organisationSettingsEn = {
  defaultCountryForm: {
    title: 'Country',
    description:
      'This will set the default value for all country related selection fields (e.g. address) in this account.',
    cta: {
      submit: 'Save country',
    },
  },
  integration: {
    title: 'Integration',
    slack: 'Slack',
    description: 'Get notifications and use the @frankieone bot',
    loading: 'Connecting to Slack',
    cta: {
      submit: 'Save country',
      connect: 'Connect',
      disconnect: 'Disconnect',
    },
    disconnect: {
      title: 'Are you sure you want to disconnect from Slack?',
      description:
        'Disconnecting the Slack will permanently delete all threads from the product.',
      cancel: 'Cancel',
      submit: 'Disconnect',
      fail: 'Failed to disconnect Slack integration. Please try again or contact support.',
      success: 'Slack integration disconnected successfully',
    },
    connect: {
      fail: 'Failed to connect Slack integration. Please try again or contact support.',
      success: 'Slack integration has been successfully connected.',
    },
  },
}
