import React, { useEffect, useMemo } from 'react'

import { useLocation } from 'react-router-dom'

import {
  FrankieButton,
  FrankieDivider,
  FrankieIcon,
  FrankieImage,
  FrankieTooltip,
} from 'frankify/src'

import {
  REACT_SIDEBAR_CUSTOM_EVENT,
  REACT_SIDEBAR_CUSTOM_EVENT_DETAIL,
} from 'app/portal-vue-compatibility'

import { AccountSelector } from 'features/account-change'
import { CustomViewNav } from 'features/custom-views'

import { useGlobalAppState } from 'entities/routing'
import { useHasFeatureFlag } from 'entities/session'

import { externalLinks } from 'shared/external-links'
import { useI18n } from 'shared/i18n'
import { getLocalStorageState, StorageKeyTypes } from 'shared/local-storage'

import { PortalNavLink } from './portal-nav-link/portal-nav-link'
import { ROUTING_KEY } from '../../locale/routing.en'
import { routingQa } from '../../qa/routing.qa'

const OPEN_SIDEBAR_BY_DEFAULT = true
const AUTO_COLLAPSE_SIDEBAR_BREAKPOINT = 1024 // width in px

type Props = {
  applicantsPath: string
  needsReviewPath: string
  analyticsPath: string
  blocklistPath: string
  canSeeApplicantsReviewPage: boolean
  canSeeApplicantsPage: boolean
  canSeeDashboardPage: boolean
  canSeeBlocklistPage: boolean
  auxiliaryApplicantPath: string
}

// eslint-disable-next-line complexity
export function PortalNav({
  blocklistPath,
  analyticsPath,
  applicantsPath,
  auxiliaryApplicantPath,
  needsReviewPath,
  canSeeApplicantsReviewPage,
  canSeeApplicantsPage,
  canSeeDashboardPage,
  canSeeBlocklistPage,
}: Props) {
  const t = useI18n(ROUTING_KEY)
  const location = useLocation()
  const [isSidebarOpenLs, setIsSidebarOpenLs] = useMemo(
    () => getLocalStorageState(StorageKeyTypes.IsSidebarOpen),
    [],
  )

  const { data, updateAppState } = useGlobalAppState()
  const [open, setOpen] = React.useState(
    Boolean(isSidebarOpenLs ?? OPEN_SIDEBAR_BY_DEFAULT),
  )

  const { isFrankie2 } = useHasFeatureFlag({ isFrankie2: 'frankie2customer' })

  const handleSetOpen = (isOpen: boolean) => () => {
    setOpen(isOpen)
  }

  useEffect(() => {
    function listener() {
      if (window.innerWidth < AUTO_COLLAPSE_SIDEBAR_BREAKPOINT) {
        setOpen(false)
      }
    }
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])

  // TODO: @ggrigorev NAV remove when new sidebar released
  useEffect(() => {
    const event = new CustomEvent(REACT_SIDEBAR_CUSTOM_EVENT, {
      detail: { [REACT_SIDEBAR_CUSTOM_EVENT_DETAIL]: open },
    })
    window.dispatchEvent(event)
    if (data?.isSidebarOpen !== open) updateAppState({ isSidebarOpen: open })
    setIsSidebarOpenLs(open)
  }, [location, open, updateAppState, setIsSidebarOpenLs])

  useEffect(() => {
    if (data?.isSidebarOpen != null && data.isSidebarOpen !== open) {
      setOpen(data.isSidebarOpen)
    }
  }, [data?.isSidebarOpen])

  return (
    <div
      className={`relative transition-[width] ease-out duration-100 h-full flex flex-col pt-4 pb-6 px-3 ${
        open ? 'w-[240px]' : 'w-[60px]'
      }`}
    >
      {open && (
        <>
          <AccountSelector applicantsPath={applicantsPath} />
          <div className="absolute bg-tertiary-grey-50 right-[-20px] top-[18px]">
            <FrankieButton
              noStyles
              className="flex p-1.5 justify-center items-center text-tertiary-grey-500
              w-8 h-8 rounded-sm
              border border-solid border-tertiary-grey-200
              active:outline focus-visible:outline outline-mono-white focus-visible:outline-3 active:outline-3
              hover:bg-tertiary-grey-100
              hover:text-tertiary-grey-800
              active:bg-primary-50
              focus-visible:bg-primary-50
              active:text-tertiary-grey-800
              focus-visible:text-tertiary-grey-800"
              singleIcon={{ name: 'mdiArrowCollapseLeft', size: 'sm' }}
              onClick={handleSetOpen(false)}
              testId={{ button: routingQa.collapseMenuCta }}
            />
          </div>
        </>
      )}
      {!open && (
        <FrankieTooltip position="right" title={t('nav.viewMenu')}>
          <FrankieButton
            noStyles
            className="flex p-2 justify-center items-center text-tertiary-grey-500
            rounded-sm
            hover:bg-tertiary-grey-100
            hover:text-tertiary-grey-800
            active:outline focus-visible:outline outline-mono-white focus-visible:outline-3 active:outline-3
            active:bg-primary-50
            focus-visible:bg-primary-50
            active:text-tertiary-grey-800
            focus-visible:text-tertiary-grey-800"
            singleIcon={{ name: 'mdiMenu', size: 'sm' }}
            onClick={handleSetOpen(true)}
            testId={{ button: routingQa.viewMenuCta }}
          />
        </FrankieTooltip>
      )}

      <FrankieDivider className="my-3" />

      <nav className="basis-full flex justify-between flex-col gap-4">
        <div className="flex flex-col flex-initial">
          {canSeeApplicantsPage && (
            <PortalNavLink
              open={open}
              activeIcon="mdiCardAccountDetails"
              icon="mdiCardAccountDetailsOutline"
              to={applicantsPath}
              isAuxiliaryActive={location.pathname.includes(
                auxiliaryApplicantPath,
              )}
              testId={routingQa.applicantsLink}
            >
              {t('nav.applicants.title')}
            </PortalNavLink>
          )}
          {canSeeApplicantsReviewPage && (
            <PortalNavLink
              open={open}
              icon="mdiListStatus"
              to={needsReviewPath}
              testId={routingQa.applicantsReviewLink}
            >
              {t('nav.applicants.review')}
            </PortalNavLink>
          )}
          {canSeeBlocklistPage && (
            <PortalNavLink
              open={open}
              icon="mdiCancel"
              to={blocklistPath}
              testId={routingQa.blocklistLink}
            >
              {t('nav.blocklist')}
            </PortalNavLink>
          )}

          <CustomViewNav open={open} applicantsPath={applicantsPath} />

          {canSeeDashboardPage && (
            <>
              <FrankieDivider className="my-3" />
              <PortalNavLink
                open={open}
                icon="mdiChartTimelineVariant"
                to={analyticsPath}
                testId={routingQa.analyticsLink}
              >
                {t('nav.analytics')}
              </PortalNavLink>
            </>
          )}

          <FrankieDivider className="my-3" />

          <PortalNavLink
            open={open}
            icon="mdiFileDocumentOutline"
            to={
              isFrankie2
                ? externalLinks.documentationV2
                : externalLinks.documentation
            }
            testId={routingQa.documentationLink}
            rel="noopener noreferrer"
            target="_blank"
            className="portal-nav-link"
            tooltip={t('nav.documentation')}
          >
            <div className="flex font-medium justify-between items-center">
              {t('nav.documentation')}
              <FrankieIcon name="mdiOpenInNew" size="xs" />
            </div>
          </PortalNavLink>
        </div>
        <FrankieImage
          className={`self-start ${open ? 'ml-2 h-5' : 'ml-[2px] h-8'}`}
          alt="FrankieOne Logo"
          src={open ? 'frankie_logo_default_light' : 'frankie_logo_round_light'}
          testId={{ image: routingQa.frankieLogo }}
        />
      </nav>
    </div>
  )
}
