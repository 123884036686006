import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { entityApi } from '../../api/entity.api'
import { ENTITY_KEY } from '../../entity.key'
import { entityEn } from '../../locale/entity.en'
import { EntityID } from '../../model/entity.model'
import { PROFILES_QUERY, ProfileStateTypes } from '../../model/profile.model'
import {
  useEntityDataQuery,
  useUpdateProfileData,
} from '../../state/entity-data/entity-data.query'

type Props = {
  state: string
  comment: string
}

export const useArchiveEntityMutation = (entityId?: EntityID) => {
  const { data } = useEntityDataQuery(entityId)
  const queryClient = useQueryClient()

  const { updateProfileData } = useUpdateProfileData(entityId)

  const serviceProfile = data?.serviceProfiles?.at(0)?.serviceName

  const t = useI18n([ENTITY_KEY], { keys: entityEn })

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (state: Props) => {
      if (!entityId) {
        throw new Error('Entity ID is required')
      }
      const { data } = await entityApi.archiveEntity(
        entityId,
        state,
        serviceProfile,
      )
      return data
    },

    onSuccess: ({ serviceProfile }, variables) => {
      // Update the cache with the new assignee
      updateProfileData([serviceProfile])

      void queryClient.refetchQueries([PROFILES_QUERY])

      notification.success(t('archiveEntity.updatedSuccess'))
      if (variables.state === ProfileStateTypes.ARCHIVED) {
        trackingManager.track(
          data?.individual?.entityType === 'INDIVIDUAL'
            ? TrackingEventsTypes.UserArchiveConfirmIndividual
            : TrackingEventsTypes.UserArchiveConfirmOrganization,
        )
      } else {
        trackingManager.track(
          data?.individual?.entityType === 'INDIVIDUAL'
            ? TrackingEventsTypes.UserUnarchiveConfirmIndividual
            : TrackingEventsTypes.UserUnarchiveConfirmOrganization,
        )
      }
    },

    onError: (_, variables) => {
      if (variables.state === ProfileStateTypes.ARCHIVED) {
        trackingManager.track(
          data?.individual?.entityType === 'INDIVIDUAL'
            ? TrackingEventsTypes.UserArchiveConfirmIndividualError
            : TrackingEventsTypes.UserArchiveConfirmOrganizationError,
        )
      } else {
        trackingManager.track(
          data?.individual?.entityType === 'INDIVIDUAL'
            ? TrackingEventsTypes.UserUnarchiveConfirmIndividualError
            : TrackingEventsTypes.UserUnarchiveConfirmOrganizationError,
        )
      }
    },
  })
  return { data, mutateAsync, isLoading }
}
