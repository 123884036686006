import { useQuery } from '@tanstack/react-query'

import { NotificationSettingsConfigKey } from 'entities/user'

import { notificationsManagementApi } from '../../api/notifications-management.api'
import {
  INotificationSettingsConfig,
  NotificationFrequenciesTypes,
} from '../../model/notifications-management.model'

export const useNotificationSettingsQuery = ({
  canFetch,
}: {
  canFetch: boolean
}) =>
  useQuery<INotificationSettingsConfig>({
    queryKey: NotificationSettingsConfigKey,
    queryFn: async () => {
      if (!canFetch) {
        throw new Error('No access')
      }
      const { data } =
        await notificationsManagementApi.getNotificationSettings()

      data.notificationSettings.forEach(setting => {
        if (setting.channel === 'EMAIL') {
          if (setting.frequency === NotificationFrequenciesTypes.REALTIME) {
            setting.canDisable = false
            setting.value = false
          }
        }
      })

      return {
        notificationSettingsDictionary: data.notificationSettingsDictionary,
        notificationSettings: data.notificationSettings,
        allNotificationChannels: data.notificationChannels,
        notificationChannels: data.notificationChannels.filter(channel =>
          data.notificationSettings.some(
            setting => setting.channel === channel.value,
          ),
        ),
      }
    },
  })
